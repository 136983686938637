<div class="login-wrap">
  <div class="inner">
    <h1>Pick a shiny new password.</h1>
    <form (submit)="submit()" [formGroup]="form" class="row" novalidate>
      <div class="input-wrap">
        <label for="password">(New Password)</label>
        <input type="password" formControlName="password" name="password" />
        <div
          class="message"
          *ngIf="form.controls['password'].invalid && form.controls['password'].touched"
        >
          You need to enter a password (min 6 characters).
        </div>
      </div>
      <div class="input-wrap">
        <label for="confirmPassword">(Confirm New Password)</label>
        <input type="password" formControlName="confirmPassword" name="confirmPassword" />
        <div
          class="message"
          *ngIf="
            form.controls['confirmPassword'].invalid && form.controls['confirmPassword'].touched
          "
        >
          Passwords must match.
        </div>
      </div>
      <button type="submit" class="dts-button black-fill">Reset My Password</button>
      <div class="message">{{ message }}</div>
      <a class="text-link" *ngIf="showLoginLink" [routerLink]="['/login', { email: email }]"
        >Click here to log in</a
      >
      <app-spinner *ngIf="loading"></app-spinner>
    </form>
  </div>
</div>
