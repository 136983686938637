import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user.component';
import { UserService } from 'app/user/user.service';

@NgModule({
  imports: [CommonModule],
  declarations: [UserComponent],
  providers: [UserService],
  exports: [UserComponent],
})
export class UserModule {}
