import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: 'admin', // default path
    loadChildren: 'app/admin/admin.module#AdminModule',
    canActivate: [AuthGuard],
  },
  {
    path: '', // default path
    loadChildren: 'app/admin/admin.module#AdminModule',
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
