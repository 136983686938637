import { Component, OnInit, Input } from '@angular/core';
import { Plan } from '../plan';
import { AppService } from '../../app.service';
import { AppStore } from '../../app-store.service';

@Component({
  selector: 'app-trial-activate-modal',
  templateUrl: './trial-activate-modal.component.html',
  styleUrls: ['./trial-activate-modal.component.scss'],
})
export class TrialActivateModalComponent implements OnInit {
  @Input() upgradeLocation: string;
  public view = 'upgrade';
  public userPlan: Plan;

  constructor(public app: AppService, public store: AppStore) {}

  ngOnInit() {
    if (this.upgradeLocation == 'footer-banner') {
      this.view = 'activate';
    }
    this.setUserPlan();
  }

  setUserPlan() {
    const planId = this.store.user.stripe_customer['subscriptions']['data'][0]['plan']['id'];
    this.userPlan = this.store.plans.find((plan) => plan.id === planId);
  }
}
