import * as Sentry from '@sentry/browser';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';

import { NgSelectModule } from '@ng-select/ng-select';

// App modules
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { UserModule } from './user/user.module';
import { PhotoModule } from './photo/photo.module';
import { SharedModule } from './shared/shared.module';

// App services

// App components
import { AppComponent } from './app.component';
import { PackModule } from 'app/pack/pack.module';
import { AdminModule } from 'app/admin/admin.module';
import { HeaderModule } from 'app/header/header.module';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { AccountModule } from 'app/account/account.module';
import { environment } from 'environments/environment';
import { AppStore } from 'app/app-store.service';
import { AppService } from 'app/app.service';
import { SearchModule } from 'app/search/search.module';
import { SignupModule } from 'app/signup/signup.module';
import { VideoModule } from 'app/video/video.module';
import { NotFoundModule } from 'app/not-found/not-found.module';
import { MessageModule } from 'app/message/message.module';
import { CustomReuseStrategy } from 'app/reuse-strategy';
import { ignoreUrls, ignoreErrors } from './sentry';
import { tokenGetter } from './token-getter';
import { EventModule } from './event/event.module';
import { FeedsModule } from './feeds/feeds.module';
import { CoreModule } from './core/core.module';
import { ExperimentService } from './experiment/experiment.service';
import { ExperimentModule } from './experiment/experiment.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { BookmarksComponent } from './bookmarks/bookmarks.component';
import { ArtistsComponent } from './artists/artists.component';
import { ArtistProfileComponent } from './artists/artist-profile/artist-profile.component';
import { FundingComponent } from './shared/funding/funding.component';

/**
 * Sentry.io error handling
 */
if (environment.production) {
  Sentry.init({
    dsn: 'https://7cf6f169eb984994b103cef55fd1c4a9@o79082.ingest.sentry.io/6729734',
    release: environment.release,
    denyUrls: ignoreUrls,
    ignoreErrors: ignoreErrors,
    environment: environment.name,
    integrations: [
      new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false,
      }),
    ],
  });
}

export class SentryErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Sentry.captureException(err);
  }
}

const sentryProvider = environment.production
  ? [{ provide: ErrorHandler, useClass: SentryErrorHandler }]
  : [];

@NgModule({
  declarations: [AppComponent, BookmarksComponent, ArtistsComponent, ArtistProfileComponent],
  imports: [
    AuthModule,
    BrowserModule,
    CoreModule,
    ExperimentModule,
    EventModule,
    FormsModule,
    MessageModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [
          'localhost:8000',
          'dts.launchagent.co.nz',
          'dts2.launchagent.co.nz',
          'app.deathtothestockphoto.com',
          'dts-v2.launchagent.co.nz',
          'dtsv2-admin.vercel.app', // dont think this one is needed
          'api.deathtothestockphoto.com',
          'dtsv2-prod-admin.vercel.app',
        ],
      },
    }),
    AppRoutingModule,
    PhotoModule,
    PackModule,
    UserModule,
    HeaderModule,
    RouterModule,
    SearchModule,
    SignupModule,
    SharedModule,
    VideoModule,
    NotFoundModule,
    FeedsModule,
    InlineSVGModule.forRoot({ baseUrl: environment.webPath }),
  ],
  providers: [
    AppStore,
    AppService,
    ...sentryProvider,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
