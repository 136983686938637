import { Injectable } from '@angular/core';
import { AppStore } from 'app/app-store.service';
import { HttpClient } from '@angular/common/http';
import { onboardingStateFactory } from './onboarding';

@Injectable()
export class OnboardingService {
  constructor(private store: AppStore, private http: HttpClient) {}

  getSubmitableData() {
    const data = { ...this.store.onboarding };
    delete data.defaultCoreValues;
    delete data.defaultIndustryValues;
    delete data.defaultMoodBoardOptions;
    return data;
  }

  send() {
    const data = this.getSubmitableData();
    return this.http.post(this.store.config.apiPath + '/user/onboarding', data);
  }

  save() {
    if (typeof localStorage != 'undefined') {
      const data = this.getSubmitableData();
      localStorage.setItem('onboarding', JSON.stringify(data));
    }
  }

  load() {
    if (typeof localStorage != 'undefined' && localStorage.getItem('onboarding')) {
      try {
        const data = JSON.parse(localStorage.getItem('onboarding'));
        this.store.onboarding = { ...onboardingStateFactory(), ...data };
      } catch (error) {
        console.log('Error loading the onboarding state');
      }
    }
  }
}
