import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppStore } from '../app-store.service';
import { Location } from '@angular/common';
import { PhotoService } from './photo.service';
import { Pack } from '../pack/pack';
import { PackService } from '../pack/pack.service';
import { AppService } from 'app/app.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
})
export class PhotoComponent implements OnInit {
  public photo;
  public pack: Pack;
  private paramsSub: Subscription;

  constructor(
    public appService: AppService,
    public route: ActivatedRoute,
    public router: Router,
    public store: AppStore,
    public photoService: PhotoService,
    public packService: PackService
  ) {}

  ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params: Params) => {
      this.photo = { id: params['id'] };
    });
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
  }
}
