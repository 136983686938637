import { Component, OnInit, Input, ViewChild } from '@angular/core';
import lottie from 'lottie-web';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() customClass = '';
  @ViewChild('animation', { static: true }) element;

  public animation;

  constructor() {}

  ngOnInit() {
    lottie.loadAnimation({
      container: this.element.nativeElement, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '../assets/animations/loading-animation.json', // the path to the animation json
    });
  }

  ngOnDestroy() {
    lottie.destroy();
  }
}
