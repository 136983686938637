<div class="page">
  <a class="back" (click)="location.back()">
    <img src="../../../assets/icons/arrow.svg" width="12" height="11" />
    Back</a
  >
  <div class="inner">
    <h1>YOUR DEATH TO STOCK VOW</h1>
    <p>
      I,
      <span class="red" *ngIf="data">{{ ' ' + data['firstname'] + ',' }}</span>
      <span class="red" *ngIf="!data && store.user">{{ ' ' + store.user.firstname + ',' }}</span>
      hereby vow to build an authentic brand that makes the internet a more beautiful place
    </p>
    <p>
      I vow from this day forward, for better or worse, to never use my death to stock resources for
      evil. I also agree to their
      <a href="https://explore.deathtostock.com/content-licence/" target="_blank"
        >content licence</a
      >
      and
      <a href="https://explore.deathtostock.com/terms-of-service/" target="_blank"
        >terms of service</a
      >.
    </p>
    <p>Till death do us part.</p>
    <img src="../../../assets/images/tilldeath.gif" width="50" height="50" />
    <div class="dts-button black-fill" (click)="agreeToLicense()">I Do</div>
    <a class="skip" (click)="notReady = true">OMG Im not ready for this commitment</a>
  </div>
</div>
<div class="notification" *ngIf="notReady">
  <div class="bg" (click)="notReady = !notReady"></div>
  <div class="inner">
    <iframe
      src="https://www.youtube.com/embed/ZXsQAXx_ao0?controls=0&autoplay=1&modestbranding=1&fs=0"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <div class="modal-close-icon" (click)="notReady = !notReady"></div>
  </div>
</div>
<app-spinner *ngIf="loading"></app-spinner>
