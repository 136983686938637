import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackService } from './pack.service';
import { HeaderModule } from '../header/header.module';

import { SharedModule } from 'app/shared/shared.module';
import { PackSingleComponent } from './pack-single/pack-single.component';
import { EventModule } from '../event/event.module';
import { PhotoModule } from '../photo/photo.module';
import { TagModule } from '../tag/tag.module';
import { SingleModule } from 'app/single/single.module';
import { FeedsModule } from 'app/feeds/feeds.module';
import { PackRelationshipFormComponent } from './pack-relationship-form/pack-relationship-form.component';
import { PackRelationshipListComponent } from './pack-relationship-list/pack-relationship-list.component';

@NgModule({
  imports: [
    CommonModule,
    EventModule,
    HeaderModule,
    SharedModule,
    PhotoModule,
    TagModule,
    SingleModule,
    FeedsModule,
  ],
  declarations: [PackSingleComponent, PackRelationshipFormComponent, PackRelationshipListComponent],
  exports: [PackRelationshipFormComponent, PackRelationshipListComponent],
  providers: [PackService],
})
export class PackModule {}
