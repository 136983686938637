import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MessageService {
  public message: Subject<string> = new Subject();
  public type: string;

  constructor() {
    this.message.next('');
  }

  /**
   * Trigger a success message
   * @param message
   */
  success(message: string) {
    this.type = 'success';
    this.message.next(message);
  }

  /**
   * Trigger an error message
   * @param message
   */
  error(message: string) {
    this.type = 'error';
    this.message.next(message);
  }

  /**
   * Trigger a warning message
   * @param message
   */
  warning(message: string) {
    this.type = 'warning';
    this.message.next(message);
  }
}
