import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { SignupComponent } from 'app/signup/signup.component';
import { SharedModule } from 'app/shared/shared.module';
import { PaymentModule } from 'app/payment/payment.module';
import { SignupExperimentComponent } from './signup-experiment/signup-experiment.component';
import { PromoCodeInputComponent } from './signup-form/promo-code-input/promo-code-input.component';
import { OnboardingModule } from 'app/onboarding/onboarding.module';

@NgModule({
  imports: [CommonModule, SharedModule, OnboardingModule, PaymentModule],
  declarations: [
    SignupComponent,
    SignupFormComponent,
    SignupExperimentComponent,
    PromoCodeInputComponent,
  ],
})
export class SignupModule {}
