import { Injectable, Inject } from '@angular/core';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { ExperimentService } from '../experiment/experiment.service';
import { DOCUMENT } from '@angular/common';

export interface pageMetaOptions {
  title: string;
  description?: string;
  slug?: string;
  image?: string;
}

@Injectable()
export class MetaService {
  public tags: Array<MetaDefinition> = [];
  public defaultUrl = 'https://app.deathtothestockphoto.com/';
  public defaultCanonicalUrl = 'https://app.deathtothestockphoto.com/';
  public defaultTitle = '💀💀💀💀💀';
  public defaultTitleExtension = '';
  public defaultDescription =
    'Death to the Stock Photo is a unique stock photo resource for creatives looking to stand out.';

  constructor(
    public title: Title,
    public meta: Meta,
    public experimentService: ExperimentService,
    @Inject(DOCUMENT) private doc
  ) {
    this.tags = [
      { name: 'description', content: this.defaultDescription },
      { property: 'og:title', content: '💀💀💀💀💀' },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:type', content: 'website' },
      { property: 'og:description', content: this.defaultDescription },
      { property: 'og:url', content: this.defaultUrl },
      { property: 'og:site_name', content: 'DTS' },
      {
        property: 'og:image',
        content:
          'https://deathtothestockphoto.com/wp-content/uploads/2017/12/Screen-Shot-2017-12-02-at-10.30.22-AM.png',
      },
    ];

    this.setTags();
  }

  /**
   * Set the page meta tags - use defaults if not supplied
   * @param tags
   */
  setTags(tags?: Array<MetaDefinition>) {
    if (!tags) {
      return this.meta.addTags(this.tags);
    }
    // Add tags aren't in default tags or override them
    for (const tag of tags) {
      if (!this.exists(tag)) {
        this.meta.addTag(tag);
      } else if (this.overrides(tag)) {
        this.meta.updateTag(tag);
      }
    }
  }

  /**
   * Reset to the default state
   */
  reset() {
    this.setTitle(this.defaultTitle);
    this.setTags(this.tags);
  }

  /**
   * Check if a tag exists
   * @param tag
   */
  exists(tag: MetaDefinition) {
    for (const t of this.tags) {
      if (t.name == tag.name || t.property == tag.property) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check if a tag overrides an existing tag
   * @param tag
   */
  overrides(tag: MetaDefinition) {
    for (const t of this.tags) {
      if ((t.name == tag.name || t.property == tag.property) && t.content != tag.content) {
        return true;
      }
    }
    return false;
  }

  setCanonicalURL(url) {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', url);
  }

  /**
   * Set the page title
   * @param title
   */
  setTitle(title?: string) {
    if (!title) {
      return this.title.setTitle(this.defaultTitle);
    }
    return this.title.setTitle(title);
  }

  /**
   * Uppcase the first letter of a string
   * @param string
   */
  ucFirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Set the meta and title for a page
   * @param options
   */
  setForPage(options: pageMetaOptions, additionalMeta?: Array<MetaDefinition>) {
    this.setTitle(options.title);
    let tags: Array<MetaDefinition> = [
      { name: 'description', content: options.title },
      { property: 'og:title', content: options.title },
      { property: 'og:description', content: options.description },
      { property: 'og:type', content: 'article' },
      { property: 'og:url', content: this.defaultUrl + options.slug },
    ];

    // Add image meta if set
    if (options.image) {
      tags.push({ property: 'og:image', content: options.image });
    }

    // Add any additional meta if set
    if (additionalMeta) {
      tags = tags.concat(additionalMeta);
    }

    this.setTags(tags);
  }
}
