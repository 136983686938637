import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppStore } from 'app/app-store.service';
import { Pack } from 'app/pack/pack';
import { NgForm } from '@angular/forms';
import { ApiMessageResponse } from '../../app.types';
@Injectable({
  providedIn: 'root',
})
export class ArtistService {
  constructor(private store: AppStore, private http: HttpClient) {}

  /**
   * Save artist to database and updates app store.
   *
   * @param data The artists data
   * @returns Promise<ApiMessageResponse>
   */
  async saveArtist(data): Promise<ApiMessageResponse> {
    const formData = new FormData();
    let response: ApiMessageResponse = { message: '' };

    formData.append('file', data.profileImageFile);
    formData.append('data', JSON.stringify(data));

    await this.http
      .post(this.store.config.apiPath + '/admin/creator/add', formData)
      .toPromise()
      .then((res) => {
        this.store.artists = res['artists'];
        response.message = 'Success';
      })
      .catch((error: HttpErrorResponse) => {
        if (error.status == 400) {
          response = error.error;
        } else {
          response.message = 'An error accrued.';
        }
      });

    return response;
  }

  /**
   * Update the current artist in the data base with the given data
   * @param data Updated data of the artist
   */
  async updateArtist(data): Promise<ApiMessageResponse> {
    const formData = new FormData();
    let response: ApiMessageResponse = { message: '' };

    formData.append('file', data.profileImageFile);
    formData.append('data', JSON.stringify(data));

    const res = await this.http
      .post(this.store.config.apiPath + '/admin/creator/update', formData)
      .toPromise()
      .then((res) => {
        this.store.artists = res['artists'];
        response.message = 'Success';
      })
      .catch((err: HttpErrorResponse) => {
        if (err.status == 400) {
          response = err.error;
        } else {
          response.message = 'An error accrued.';
        }
      });
    return response;
  }

  /**
   * Delete an artist by Id.
   *
   * Delete will not work if the artist is still linked to packs.
   * @param id The id of the artist
   */
  async deleteArtist(id) {
    return await this.http
      .delete<responseBody>(this.store.config.apiPath + `/admin/creator/${id}`)
      .toPromise();
  }

  async loadAllArtists() {
    if (this.store.artists?.length) {
      return this.store.artists;
    }
    const res = await this.http.get(this.store.config.apiPath + '/creators').toPromise();
    this.store.artists = res['creators'];
    return res['creators'];
  }

  getArtistStoreIndex(artistId) {
    let index = null;
    for (const key in this.store.artists) {
      if (this.store.artists[key].id == artistId) {
        index = key;
        break;
      }
    }
    return index;
  }

  getFeaturedArtist(artistArray) {
    const artist = artistArray.filter((artist) => artist.is_featured == 1);
    return artist[0] ?? null;
  }
}

interface responseBody {
  message: string;
}
