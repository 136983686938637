import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoPackFeedComponent } from './photo-pack-feed/photo-pack-feed.component';
import { EditorialFeedComponent } from './editorial-feed/editorial-feed.component';
import { SharedModule } from 'app/shared/shared.module';
import { TagModule } from 'app/tag/tag.module';
import { VideosFeedComponent } from './videos-feed/videos-feed.component';
import { SingleModule } from 'app/single/single.module';
import { PhotoGridComponent } from './photo-grid/photo-grid.component';
import { VideoModule } from 'app/video/video.module';
import { FeedFooterComponent } from './feed-footer/feed-footer.component';
import { OnboardingModule } from '../onboarding/onboarding.module';
import { BannerComponent } from './banner/banner.component';

@NgModule({
  imports: [CommonModule, SharedModule, TagModule, SingleModule, VideoModule, OnboardingModule],
  declarations: [
    PhotoPackFeedComponent,
    EditorialFeedComponent,
    VideosFeedComponent,
    PhotoGridComponent,
    FeedFooterComponent,
    BannerComponent,
  ],
  exports: [PhotoGridComponent, FeedFooterComponent],
})
export class FeedsModule {}
