import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'dts-select',
  templateUrl: './dts-select.component.html',
  styleUrls: ['./dts-select.component.scss'],
})
export class DtsSelectComponent implements OnInit {
  @Input() selectOptions: Array<Object>;
  @Input() initialValue: string;
  @Output() onChange = new EventEmitter();

  public model;

  constructor() {}

  ngOnInit(): void {
    this.model = this.initialValue;
  }

  handleChange(data) {
    this.onChange.emit(this.model);
  }
}
