import { Photo } from 'app/photo/photo';
import { Tag } from '../tag/tag';
import { NullTemplateVisitor } from '@angular/compiler';

export class Pack {
  id: number;
  url: string;
  date: string;
  status: string;
  name: string;
  description: string;
  zip_file: string;
  artist: any;
  artist_id: number;
  artist_name: string;
  artist_website: string;
  artist_instagram: string;
  artist_youtube: string;
  artist_facebook: string;
  creative_director_name: string;
  creative_director_instagram: string;
  additional_data: any;
  budget_line?: number;
  featured: boolean;
  featured_photo_id: number;
  featured_photo: Photo;
  photos: Array<Photo>;
  published: number;
  published_on: string;
  related_packs: Pack[];
  related_image: Photo;
  related_photo_id: number;
  tags: Tag[];
  type: string;
  video_url: string;
  video_placeholder_image: string;
  video_height: number;
  video_width: number;
  scrollAmt?: number;
  scrollEnd?: boolean;
  scrollDiff?: number;
  hidden?: boolean;
}

export interface PackMap {
  [key: number]: Pack;
}

export function packFactory(): Pack {
  return {
    id: null,
    url: null,
    date: null,
    status: null,
    name: null,
    description: null,
    zip_file: null,
    artist: null,
    artist_id: null,
    artist_name: null,
    artist_website: null,
    artist_instagram: null,
    artist_youtube: null,
    artist_facebook: null,
    featured: true,
    featured_photo_id: null,
    featured_photo: null,
    photos: null,
    published: null,
    published_on: null,
    related_packs: null,
    related_image: null,
    related_photo_id: null,
    tags: null,
    type: null,
    video_url: null,
    video_placeholder_image: null,
    video_height: null,
    video_width: null,
    creative_director_name: null,
    creative_director_instagram: null,
    additional_data: null,
  };
}
