import { Injectable } from '@angular/core';
import { AppStore } from 'app/app-store.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PaymentService {
  constructor(private store: AppStore, private http: HttpClient) {}

  process(data) {
    return this.http.post(this.store.config.apiPath + '/payment/', data);
  }
}
