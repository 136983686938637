import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from 'app/auth/auth.service';
import { AppStore } from 'app/app-store.service';

@Injectable()
export class AuthActiveGuard implements CanActivate {
  constructor(private store: AppStore, private auth: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.store.user && (this.store.user.is_active || this.store.user.is_admin)) {
      return true;
    } else {
      return this.store.userLoaded.map((val) => {
        if (val && (this.store.user.is_active || this.store.user.is_admin)) {
          return true;
        } else if (val && !this.store.user.is_active) {
          this.router.navigate(['/account/billing']);
          return false;
        }
      });
    }
  }
}
