import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from 'environments/environment';
import { User, UserMap } from 'app/user/user';
import { Subject } from 'rxjs';
import { Pack, PackMap } from 'app/pack/pack';
import { Photo, PhotoMap } from 'app/photo/photo';
import { Plan } from 'app/shared/plan';
import { Coupon } from './shared/coupon';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { AnalyticsService } from './core/analytics.service';
import { Tag } from './tag/tag';
import { OnboardingState, onboardingStateFactory } from './onboarding/onboarding';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppStore {
  public banner = {};
  public config = {
    webPath: '',
    apiPath: '',
    downloadPath: '',
    stripePublishableKey: '',
  };
  public loggedIn: Subject<boolean> = new Subject();
  public currentUserId: number;
  public currentFeed: Array<any> | boolean = [];
  public currentFeedRow = 0;
  public currentFeedIndex = 0;
  public userLoaded: Subject<boolean> = new Subject();
  public userIdentify: Subject<User> = new Subject();
  public isAdmin = false;
  public isE2E: boolean;
  public users: UserMap = {};
  public usersTotal: number;
  public artists: any;
  public packs: PackMap = {};
  public photos: PhotoMap = {};
  public humanReviewCount = 0;
  public humanReviewNextId: number;
  public isBrowser: boolean;
  public isServer: boolean;
  public onboarding: OnboardingState = onboardingStateFactory();
  public token: string;
  public headerType: string;
  public feed: string;
  public feedStyle = 'default';
  public fullWidth = true;
  public closeInactiveModal: boolean;
  public coupon: Coupon;
  public selectedCoupon: any;
  public bookmark_change: Subject<boolean> = new Subject();

  public algolia = {
    recentQueryId: null,
    searchTerm: null,
  };

  public windowResizeFinished: Subject<boolean> = new Subject();

  public settings: Object = {
    bookmarks: {
      colors: {
        bck_color: null,
        text_color: null,
      },
    },
  };
  public coupons: Array<Coupon> = [
    {
      id: 'insider-discount',
      plan_id: 'premium_annual_145',
      amount: 46.0,
      discounted_price: 99,
    },
  ];
  public plans: Array<Plan> = [
    {
      id: 'experiment_13',
      name: 'Brand Monthly - $15/MO',
      price: 15.0,
      period: 'monthly',
      trialPeriodDays: 14,
      description: 'A monthly subscription to all Death to Stock Media.',
    },
    {
      id: 'premium_annual_145',
      name: 'Brand Yearly - $145/YR',
      price: 145.0,
      period: 'yearly',
      trialPeriodDays: 14,
      description: 'A yearly subscription to all Death to Stock Media.',
    },
    {
      id: 'agency_monthly',
      name: 'Agency Monthly - $25/MO',
      price: 25.0,
      period: 'monthly',
      trialPeriodDays: 14,
      description: 'A monthly agency subscription to all Death to Stock Media.',
    },
    {
      id: 'agency_monthly_39',
      name: 'Agency Monthly',
      price: 39.0,
      period: 'monthly',
      trialPeriodDays: 14,
      description: 'A monthly agency subscription to all Death to Stock Media.',
    },
    {
      id: 'agency_yearly',
      name: 'Agency Yearly - $250/YR',
      price: 250.0,
      period: 'yearly',
      trialPeriodDays: 14,
      description: 'A yearly agency subscription to all Death to Stock Media.',
    },
    {
      id: 'agency_yearly_399',
      name: 'Agency Yearly - $399/YR',
      price: 399.0,
      period: 'yearly',
      trialPeriodDays: 14,
      description: 'A yearly agency subscription to all Death to Stock Media.',
    },
    {
      id: 'plan_DiUzSZpcasS5mx',
      name: 'Partner Annual - $399/YR',
      price: 399.0,
      period: 'yearly',
      trialPeriodDays: 14,
      description: 'A yearly partner subscription to all Death to Stock Media.',
    },
  ];
  public monthlyPlan: Plan = {
    id: 'experiment_13',
    name: 'Brand Monthly - $15/MO',
    price: 15.0,
    period: 'monthly',
    trialPeriodDays: 14,
    description: 'A monthly subscription to all Death to Stock Media.',
  };
  public yearlyPlan: Plan = {
    id: 'premium_annual_145',
    name: 'Brand Yearly - $145/YR',
    price: 145.0,
    period: 'yearly',
    trialPeriodDays: 14,
    description: 'A yearly subscription to all Death to Stock Media.',
  };

  public searchTerm = '';
  public searchIds: number[] = [];

  public featuredTagList: Tag[] = [
    { name: 'people of color', id: 2799 },
    { name: 'women', id: 1655 },
    { name: 'work', id: 64 },
    { name: 'beauty', id: 1239 },
    { name: 'studio', id: 81 },
    { name: 'design', id: 463 },
    { name: 'wellness', id: 1003 },
    { name: 'people', id: 536 },
    { name: 'artist', id: 667 },
    { name: 'digital', id: 425 },
    { name: 'textures', id: 2874 },
    { name: 'fashion', id: 9 },
    { name: 'therapy', id: 4376 },
    { name: 'home & family', id: 4376 },
    { name: 'adventure', id: 206 },
    { name: 'travel', id: 3 },
    { name: 'team', id: 502 },
    { name: 'food & drinks', id: 4378 },
    { name: 'architecture', id: 698 },
  ];

  public trialMode: boolean;
  public trialUpgradeModal: boolean;
  public trialUpgradeLocation: string;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient) {
    this.config.webPath = environment.webPath;
    this.config.downloadPath = environment.webPath + '/download/';
    this.config.apiPath = environment.apiPath;
    this.config.stripePublishableKey = environment.stripePublishableKey;
    this.userLoaded.next(false);
    this.loggedIn.next(false);
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.isServer = isPlatformServer(this.platformId);

    // Set if we are in an E2E testing environment with
    this.isE2E = this.isBrowser && window['Cypress'] ? true : false;
  }

  /**
   * Set the default plans based on the subscription type - agency, artist or partner
   * @param type
   */
  setPlanType(type: string) {
    switch (type) {
      case 'agency':
        this.monthlyPlan = this.plans.find((plan) => plan.id == 'agency_monthly_39');
        this.yearlyPlan = this.plans.find((plan) => plan.id == 'agency_yearly_399');
        break;
      case 'partner':
        this.yearlyPlan = this.plans.find((plan) => plan.id == 'plan_DiUzSZpcasS5mx');
        this.monthlyPlan = null;
        break;
      default:
        this.monthlyPlan = this.plans.find((plan) => plan.id == 'experiment_13');
        this.yearlyPlan = this.plans.find((plan) => plan.id == 'premium_annual_145');
        break;
    }
  }

  /**
   * Getter for logged in user
   */
  get user() {
    return this.currentUserId ? this.users[this.currentUserId] : null;
  }

  /**
   * Setter for logged in users
   */
  set user(user: User) {
    if (user) {
      this.currentUserId = user.id;
      this.users[user.id] = user;
      this.user.is_active = true;
      this.user.billing_status = 'active';
      this.user.is_full_admin = user.is_admin == true ? true : false;
      this.trialMode = false;
      this.userLoaded.next(true);
      // if (!user.is_admin) {
      this.userIdentify.next(user);
      // }
    } else {
      this.currentUserId = null;
      this.userLoaded.next(false);
    }
  }

  /**
   * Loop through all the user roles to see if they have an active role or are free forever
   */
  userIsActive() {
    for (const role of this.user.roles) {
      if (role.name === 'active' || role.name === 'free forever' || role.name === 'exiting') {
        return true;
      }
    }
    return false;
  }

  /**
   * Get the billing status of the user
   */
  getBillingStatus() {
    for (const role of this.user.roles) {
      if (role.name === 'active' || role.name === 'inactive' || role.name === 'exiting') {
        return role.name;
      }
    }
    return 'inactive';
  }

  public impressions = [];
  storeImpressions(impression) {
    const sizeArrayToSend = 25;

    const impressionData = {
      item_id: impression.id,
      type: impression.video_url ? 'video' : 'photo',
      feed: this.feed ?? null,
      search_term: this.algolia.searchTerm ?? null,
      timestamp: new Date(),
    };

    // check if it exists in array already
    let i = 0;
    for (i = 0; i < this.impressions.length; i++) {
      if (
        this.impressions[i].item_id == impressionData.item_id &&
        this.impressions[i].feed == impressionData.feed
      ) {
        return true;
      }
    }

    this.impressions.push(impressionData);
    if (this.impressions.length >= sizeArrayToSend) {
      const toSend = { impressions: [...this.impressions] };
      this.impressions = [];
      try {
        this.http.post(this.config.apiPath + '/event/impression/', toSend).toPromise();
      } catch (error) {
        // silent catch
      }
    }
  }
}
