<div class="login-wrap">
  <div class="inner">
    <h1>Don’t worry. It happens to the best of us.</h1>
    <form (submit)="resetPassword()" [formGroup]="form" class="row" novalidate>
      <div class="input-wrap">
        <label for="email">(Email)</label>
        <input type="email" formControlName="email" name="email" />
      </div>
      <button type="submit" class="dts-button black-fill">Reset My Password</button>
      <div class="message">{{ message }}</div>
      <app-spinner *ngIf="loading"></app-spinner>
    </form>
  </div>
</div>
