import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { AlgoliaService } from './algolia.service';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule],
  declarations: [SearchComponent],
  exports: [SearchComponent],
  providers: [AlgoliaService],
})
export class SearchModule {}
