import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleComponent } from './single.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [SingleComponent],
  exports: [SingleComponent],
})
export class SingleModule {}
