import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Experiment, ExperimentMap } from './experiment';
import { CookieService } from '../core/cookie.service';
import { experiments } from './experiment.data';
import { AppStore } from 'app/app-store.service';

@Injectable()
export class ExperimentService {
  private prefix = 'exp_'; // Used for conforming analytics event ids to set format
  public experimentData: Array<Experiment> = experiments;
  private cookieExpireDays = 1800; // Expires in 5 years
  public experiments: ExperimentMap = {};
  public viewEvent: Subject<Object> = new Subject();
  public viewedEvents: Array<string> = [];

  constructor(private cookieService: CookieService, private store: AppStore) {
    this.checkDataDuplicates();
  }

  /**
   * This needs to be run once the app component has initted for the cookie to have been parsed
   */
  public init() {
    // Loop through the configured experimentData and load for use
    for (const experiment of this.experimentData) {
      const variationId = this.cookieService.getItem(this.prefix + experiment.id) || null;
      this.experiments[experiment.id] = variationId;
    }
  }

  /**
   * Make sure our config data doesn't have any duplicate keys
   */
  private checkDataDuplicates() {
    const experiments = [];
    for (const exp of this.experimentData) {
      if (experiments.indexOf(exp.id) >= 0) {
        throw new Error(
          'You have duplicates in your experiment data. Make sure you have unique experiment ids for each experiment in /experiment/experiment.data.ts'
        );
      }
      experiments.push(exp.id);
    }
  }

  public appendActiveExperiments() {
    const experiments = {};
    if (this.experimentData) {
      for (const experiment of this.experimentData) {
        if (experiment.status === 'active') {
          const userValue = this.cookieService.getItem(this.prefix + experiment.id) || null;
          experiments[this.prefix + experiment.id] = userValue;
        }
      }
    }
    return experiments;
  }

  /**
   * Pick a random variation biased by weight
   * @param list
   * @param weight
   */
  private getRandomVariation(list, weight) {
    const total_weight = weight.reduce(function (prev, cur, i, arr) {
      return prev + cur;
    });

    const random_num = this.rand(0, total_weight);
    let weight_sum = 0;

    for (let i = 0; i < list.length; i++) {
      weight_sum += weight[i];
      weight_sum = +weight_sum.toFixed(2);

      if (random_num <= weight_sum) {
        return list[i];
      }
    }
  }

  /**
   * Does this session have a variation for a specific experiment
   * @param experimentId
   * @param variationId
   */
  public hasExperimentVariation(experimentId: string, variationId: string): boolean {
    const experiment = this.experimentData.find((exp) => exp.id === experimentId);
    const variation = experiment.variations.find((vtn) => vtn.id === variationId);
    const userCookieValue = this.cookieService.getItem(this.prefix + experimentId);
    return variation && (!userCookieValue || variationId === userCookieValue) ? true : false;
  }

  /**
   * Generate a random number in a range
   */
  private rand(min, max) {
    return Math.random() * (max - min) + min;
  }

  /**
   * Set the experimentId and variationId in the cookie and on the experiments array
   * @param experimentId
   * @param variationId
   */
  public setExperiment(experimentId: string, variationId: string) {
    // if this is the browser, store a cookie
    this.cookieService.setItem(this.prefix + experimentId, variationId, this.cookieExpireDays);

    // then init the experiment again to set the new experiment values
    this.init();
  }

  /**
   * Set the cookie for a viewed experiment
   */
  public viewed(experimentId: string) {
    let variationId = this.experiments[experimentId];
    const experiment = this.experimentData.find((exp) => exp.id === experimentId);

    if (!this.cookieService.getItem(this.prefix + experimentId)) {
      // Pick a random weighted one
      const weights = experiment.variations.map((variation) => variation.weight);
      const randomVariation = this.getRandomVariation(experiment.variations, weights);
      variationId = randomVariation.id;
      this.cookieService.setItem(this.prefix + experimentId, variationId, this.cookieExpireDays);
    }

    if (this.store.isBrowser && !this.viewedEvents.includes(experimentId) && variationId) {
      this.viewedEvents.push(experimentId);

      // Trigger an analytics event
      const options = {};
      options[this.prefix + experimentId] = variationId;

      this.viewEvent.next({ event: 'Viewed Experiment', options });
    }
  }
}
