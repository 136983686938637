import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { LoginComponent } from 'app/auth/login/login.component';
import { AuthForgotPaswordComponent } from 'app/auth/forgot-password/auth-forgot-pasword.component';
import { AuthResetPasswordComponent } from 'app/auth/reset-password/auth-reset-password.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: AuthForgotPaswordComponent,
  },
  {
    path: 'reset-password/:email/:token',
    component: AuthResetPasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
