import { Injectable } from '@angular/core';
import * as algoliasearch from 'algoliasearch';
import { environment } from 'environments/environment';
import { AppStore } from '../app-store.service';
import { Subject } from 'rxjs';
import { Tag } from '../tag/tag';
import { badWords } from './bad-words';

@Injectable()
export class AlgoliaService {
  private client;
  private photosIndex;
  private tagsIndex;

  constructor(private store: AppStore) {
    this.client = algoliasearch(environment.algoliaApp, environment.algoliaAppKey);
    this.photosIndex = this.client.initIndex('photos_created_desc');
    this.tagsIndex = this.client.initIndex('tags');
  }

  /**
   * Get search results from photos index
   * @param term
   */
  search(term: string, offset: number = 0, length: number = 24): Subject<number[] | any> {
    const results = new Subject();
    // Dont return results for bad words
    if (badWords.indexOf(term.toLowerCase()) >= 0) {
      this.store.searchIds = [];
      setTimeout(() => {
        results.next(this.store.searchIds);
      }, 1000);
    } else {
      this.photosIndex.search(
        {
          query: term,
          offset,
          length,
          filters: `published = 1`,
          clickAnalytics: true,
        },
        (err, content) => {
          if (err) {
            throw err;
          }
          this.store.algolia = {
            recentQueryId: content.queryID,
            searchTerm: term,
          };
          this.store.searchIds = content.hits.map((hit) => hit['id']);
          results.next(this.store.searchIds);
        }
      );
    }

    return results;
  }

  /**
   * Get list of tags matching the search term or partial search term
   * @param term
   */
  searchTags(term: string): Subject<Tag[] | any> {
    const results = new Subject();
    this.tagsIndex.search({ query: term }, (err, content) => {
      if (err) {
        throw err;
      }
      results.next(content.hits);
    });
    return results;
  }

  async searchBarTags(term: string) {
    const res = await this.tagsIndex.search({ query: term });
    return res.hits;
  }
}
