<div class="content">
  <div class="error-page">
    <h1>Page Not Found</h1>
    <p>
      We're sorry, but the page you have requested is not available. Please use the<br />
      link below to return to the last page you were on.
    </p>
    <div class="button-wrap">
      <a href="javascript:history.back()" class="button btn-success">Back</a>
    </div>
  </div>
</div>
