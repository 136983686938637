import { Component, OnInit } from '@angular/core';
import { MessageService } from 'app/message/message.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  public state: boolean;
  public delay = 3000;

  constructor(public messageService: MessageService) {}

  ngOnInit() {
    this.messageService.message.subscribe((message) => {
      if (message != '') {
        this.state = true;
        setTimeout(() => {
          this.state = false;
        }, this.delay);
      }
    });
  }
}
