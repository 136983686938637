import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { ActivatedRoute } from '@angular/router';
import { AppStore } from '../../app-store.service';

@Component({
  selector: 'app-trial-footer-banner',
  templateUrl: './trial-footer-banner.component.html',
  styleUrls: ['./trial-footer-banner.component.scss'],
})
export class TrialFooterBannerComponent implements OnInit {
  constructor(public app: AppService, public route: ActivatedRoute, public store: AppStore) {}

  ngOnInit() {}
}
