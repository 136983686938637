import { Injectable } from '@angular/core';
import { AppStore } from 'app/app-store.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';
import { AnalyticsService } from 'app/core/analytics.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface Credentials {
  email: string;
  password: string;
}

@Injectable()
export class AuthService {
  constructor(
    public analyticsService: AnalyticsService,
    private store: AppStore,
    private http: HttpClient,
    public jwtHelper: JwtHelperService
  ) {
    const token = localStorage.getItem('token');
    if (token && this.tokenIsValid(token)) {
      this.store.token = token;
      this.store.loggedIn.next(true);
    } else {
      this.logout();
    }
  }

  /**
   * Is the token valid
   * @param token
   */
  tokenIsValid(token) {
    try {
      const decoded = this.jwtHelper.decodeToken(token);
      if (decoded && this.store.isE2E) {
        return true;
      }
      if (
        !this.jwtHelper.isTokenExpired(token) &&
        (decoded.iss == environment.apiPath + '/user/login' ||
          decoded.iss == environment.apiPath + '/signup')
      ) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  loggedIn() {
    const token = localStorage.getItem('token');
    return this.tokenIsValid(token);
  }

  /**
   * Login a user
   * @param creds Credentials - user email and password
   * @return Observable
   */
  login(creds: Credentials): Observable<any> {
    // Perform the login request to the server
    return this.http.post(this.store.config.apiPath + '/user/login', creds).pipe(
      switchMap((res) => {
        console.log({ res });

        // Successful login
        localStorage.setItem('token', res['access_token']);
        this.store.user = res['user_data']['user'];
        this.store.userLoaded.next(true);
        this.store.loggedIn.next(true);
        return of(res);
      })
    );
  }

  /**
   * Log the user out by removing the token from storage
   */
  logout() {
    // clear the token from local storeage
    localStorage.removeItem('token');
    // clear the user from the store
    this.store.user = null;
    this.store.userLoaded.next(false);
    // Track the event in Segment
    this.analyticsService.track('Logged out');
    // clear the token from the store
    this.store.token = null;

    window['Beacon']('destroy');
  }

  /**
   *  Send a password reset email to the user
   */
  sendPasswordReset(email: string): Observable<any> {
    return this.http.post(this.store.config.apiPath + '/user/forgot-password', { email });
  }

  /**
   *  Reset the user's password
   */
  resetPassword(
    token: string,
    email: string,
    password: string,
    password_confirmation: string
  ): Observable<any> {
    return this.http.post(this.store.config.apiPath + '/user/reset-password', {
      token,
      email,
      password,
      password_confirmation,
    });
  }
}
