<div class="single-modal">
  <div class="bg" (click)="app.goBack()"></div>
  <div class="inner">
    <div class="modal-close-icon" (click)="app.goBack()"></div>
    <div class="control-wrap">
      <div
        class="arrow prev"
        (click)="prev()"
        *ngIf="store.currentFeed && (store.currentFeedIndex > 0 || store.currentFeedRow > 0)"
      >
        <div class="icon" [inlineSVG]="'/assets/icons/arrow.svg'"></div>
      </div>
      <div class="single">
        <div class="imgContainer" #left>
          <div
            class="placeholder"
            *ngIf="item && placeholderWidth && placeholderHeight"
            [ngStyle]="caculatePlaceholderStyles(placeholderWidth, placeholderHeight)"
            [style.backgroundColor]="
              item.video_url ? item.pack.dominant_hex_code : item.dominant_hex_code
            "
          >
            <img
              *ngIf="item && !item.video_url && item.dimensions && item.full_image_url"
              imgix-image
              imgix-url="{{ item.full_image_url }}"
              imgix-type="{{ isGif ? 'gif' : null }}"
              (load)="showImg = true"
              [ngClass]="{ show: showImg }"
            />
            <iframe
              [src]="app.vimeoEmbedURL(item.video_url, false) | safe"
              *ngIf="item.video_url"
              frameborder="0"
            ></iframe>
          </div>
        </div>

        <div class="toolBar" *ngIf="item">
          <div class="det" *ngIf="item.pack && item.pack.name">
            <div *ngIf="!item.pack.hidden">
              <div class="blackLine"></div>
              <div class="line" *ngIf="!item.video_url">
                <p>(Pack)</p>
                <a
                  data-cy="photo-pack-link"
                  [routerLink]="['/pack/' + item.pack.id]"
                  data-cy="photo-pack-link"
                  >{{ item.pack.name }}</a
                >
              </div>
              <div class="line" *ngIf="item.video_url">
                <p>(Pack)</p>
                <span>{{ item.pack.name }}</span>
              </div>
            </div>

            <div class="line" *ngIf="artist">
              <p>(Artist)</p>
              <a [routerLink]="['/artist/' + artist.slug]">
                {{ artist.full_name }}
              </a>
            </div>

            <div class="line" *ngIf="item.pack.creative_director_name">
              <p>(Creative Director)</p>
              <a
                href="{{ item.pack.creative_director_instagram }}"
                target="_blank"
                *ngIf="item.pack.creative_director_instagram"
              >
                {{ item.pack.creative_director_name }}
              </a>
              <span *ngIf="!item.pack.creative_director_instagram">
                {{ item.pack.creative_director_name }}
              </span>
            </div>

            <div class="line" *ngIf="item.video_url && item.pack.budget_line">
              <p>(Project Budget)</p>
              <span>${{ item.pack.budget_line | number: '1.0-0' }}</span>
            </div>

            <div class="blackLine"></div>

            <div class="line">
              <p>(Toolbar)</p>
            </div>

            <div class="toolbarControls">
              <!-- Bookmark Control -->
              <div class="control bookmarks">
                <ng-container *ngIf="!is_bookmarked">
                  <p>Add to Bookmarks</p>

                  <div
                    class="button"
                    (click)="add_bookmark()"
                    [ngClass]="{ loading: bookmark_loading }"
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="25" height="25" rx="12.5" fill="black" />
                      <path
                        d="M12.7603 14.2194L12.5 14.0607L12.2397 14.2194L7.5 17.1095V7.5H17.5V17.1095L12.7603 14.2194Z"
                        stroke="white"
                      />
                    </svg>
                  </div>
                </ng-container>
                <ng-container *ngIf="is_bookmarked">
                  <p>Remove from Bookmarks</p>
                  <div
                    class="button"
                    (click)="remove_bookmark()"
                    [ngClass]="{ loading: bookmark_loading }"
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.5 12.5C24.5 5.87258 19.1274 0.5 12.5 0.5C5.87258 0.5 0.5 5.87258 0.5 12.5C0.5 19.1274 5.87258 24.5 12.5 24.5C19.1274 24.5 24.5 19.1274 24.5 12.5Z"
                        stroke="black"
                      />
                      <path
                        d="M12.7603 14.2194L12.5 14.0607L12.2397 14.2194L7.5 17.1095V7.5H17.5V17.1095L12.7603 14.2194Z"
                        fill="black"
                        stroke="black"
                      />
                    </svg>
                  </div>
                </ng-container>
              </div>

              <!-- <div class="control">
                <p>Paint by </p>
                <div class="button">

                </div>
              </div> -->

              <!-- Download Control -->
              <div class="control">
                <div class="dtsSelectContainer" *ngIf="!item.video_url && !isGif">
                  <dts-select
                    [selectOptions]="selectOptions"
                    [initialValue]="downloadRes"
                    (onChange)="changeRes($event)"
                  ></dts-select>
                </div>

                <p *ngIf="item.video_url || isGif">Download</p>

                <div class="button" data-cy="download-button">
                  <ng-container *ngIf="!store.trialMode">
                    <a
                      *ngIf="item && item.full_image_url && !item.video_url && downloadRes"
                      imgix-image
                      [imgix-url]="item.full_image_url"
                      [imgix-width]="downloadRes"
                      [imgix-height]="downloadRes"
                      [imgix-type]="'download'"
                      [imgix-name]="item.name"
                      [imgix-id]="item.id"
                      [imgix-quality]="100"
                      (click)="photoService.trackDownload(item.pack, item); downloadClickedAnim()"
                      [ngClass]="{ outline: isGif }"
                    >
                      <div class="download-button-container">
                        <img
                          class="download-icon"
                          *ngIf="!showDownloadAnimation"
                          src="../../assets/icons/gradientDownload.svg"
                          alt=""
                        />
                        <div
                          [style.visibility]="showDownloadAnimation ? 'visible' : 'hidden'"
                          class="downloadAnimBtn"
                        >
                          <div class="animation"></div>
                        </div>
                      </div>
                    </a>

                    <a
                      *ngIf="item && item.video_url && item.pack"
                      [href]="item.pack.zip_file"
                      (click)="
                        packService.trackDownload(store.packs[item.pack.id]); downloadClickedAnim()
                      "
                    >
                      <div class="download-button-container">
                        <img
                          class="download-icon"
                          *ngIf="!showDownloadAnimation"
                          src="../../assets/icons/gradientDownload.svg"
                          alt=""
                        />
                        <div
                          [style.visibility]="showDownloadAnimation ? 'visible' : 'hidden'"
                          class="downloadAnimBtn"
                        >
                          <div class="animation"></div>
                        </div>
                      </div>
                    </a>
                  </ng-container>

                  <ng-container *ngIf="store.trialMode">
                    <a
                      *ngIf="!item.video_url"
                      (click)="app.toggleTrialUpgradeModal('single-photo')"
                    >
                      <img src="../../assets/icons/gradientDownload.svg" alt="" />
                    </a>

                    <a
                      *ngIf="item && item.video_url"
                      (click)="app.toggleTrialUpgradeModal('single-video')"
                    >
                      <img src="../../assets/icons/gradientDownload.svg" alt="" />
                    </a>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="blackLine"></div>
          </div>
        </div>

        <div class="tags" *ngIf="tags && tags.length > 0">
          <h3>(Tags)</h3>
          <div class="tag-list">
            <div *ngFor="let tag of tags">
              <div class="tag" (click)="searchForTerm(tag.name)">
                {{ tag.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="arrow next"
        (click)="next()"
        *ngIf="
          store.currentFeed &&
          store.currentFeed[store.currentFeedRow] &&
          (store.currentFeed[store.currentFeedRow + 1] ||
            store.currentFeedIndex < store.currentFeed[store.currentFeedRow].length - 1)
        "
      >
        <div class="icon" [inlineSVG]="'/assets/icons/arrow.svg'"></div>
      </div>
    </div>
  </div>
</div>
