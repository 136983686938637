import { Injectable } from '@angular/core';
import { Photo } from 'app/photo/photo';
import { AppStore } from 'app/app-store.service';
import { Observable } from 'rxjs/Observable';
import { of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { FeedOptions } from 'app/shared/feed-options';
import { Pack, packFactory } from 'app/pack/pack';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { Tag } from '../tag/tag';
import { EventService } from '../event/event.service';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { AlgoliaInsightsService } from '../shared/algolia/insights.service';
import { PackService } from 'app/pack/pack.service';
import { isEmpty } from 'lodash';

@Injectable()
export class PhotoService {
  public loadingPhotos = false;
  public photosChanged = new Subject();

  constructor(
    private store: AppStore,
    private http: HttpClient,
    public router: Router,
    private eventService: EventService,
    public insights: AlgoliaInsightsService,
    private packs: PackService
  ) {}

  /**
   * Upload photos to the server
   */
  upload(packId: any, data: FormData): Observable<any> {
    data.append('pack_id', packId);
    const request = new HttpRequest('POST', this.store.config.apiPath + '/admin/photo/', data, {
      reportProgress: true,
    });
    const response = this.http.request<Photo[]>(request);
    return response.switchMap((res) => {
      if (res.type == HttpEventType.Response) {
        const data = res.body;
        data.map((photo) => (this.store.photos[photo.id] = photo));
      }
      return of(res);
    });
  }

  /**
   * Get photos and set them on the store
   * @return Observable
   */
  get(options?: FeedOptions): Observable<number[]> {
    this.loadingPhotos = true;
    const ids = [];
    options = options ? options : {};
    // Build the endpoint
    const endpoint = '/photos';

    if (this.store.photos[options.id] && this.store.photos[options.id].tags) {
      return of([options.id]);
    }

    // If we don't have photos in the store or a search then make a request for them
    return this.http.post<Photo[]>(this.store.config.apiPath + endpoint, { ...options }).pipe(
      switchMap((data) => {
        // Map the reponse to the store and search ids
        data['photos'].map((photo) => {
          this.store.photos[photo.id] = photo;
          ids.push(photo.id);
        });
        this.loadingPhotos = false;
        // Dont reverse when loading
        return of(ids);
      })
    );
  }

  /**
   * Get photos and set them on the store
   * @return Observable
   */
  getFromIds(requestIds: number[], options?: FeedOptions): Observable<number[]> {
    this.loadingPhotos = true;
    const ids = [];

    // If we don't have photos in the store or a search then make a request for them
    return this.http
      .post<{ photos: Photo[] }>(this.store.config.apiPath + '/photos/', {
        idArray: requestIds,
        options,
      })
      .pipe(
        switchMap(({ photos }) => {
          // Map the reponse to the store and search ids
          photos.map((photo) => {
            this.store.photos[photo.id] = photo;
            ids.push(photo.id);
          });
          this.loadingPhotos = false;
          // Dont reverse when loading
          return of(ids);
        })
      );
  }

  /**
   * Get photos and set them on the store
   * @return void
   */
  async getPhotosFromIds(requestIds: number[]) {
    this.loadingPhotos = true;

    const photos = await this.http
      .post<{ photos: Photo[] }>(this.store.config.apiPath + '/photos/', {
        idArray: requestIds,
        options: { limit: 1000 },
      })
      .toPromise()
      .then((data) => {
        return data.photos;
      });

    photos.map((photo) => {
      this.store.photos[photo.id] = photo;
    });
    this.loadingPhotos = false;
  }

  /**
   * Update a photo
   * @param photo Photo - the photo data to update
   * @return Observable
   */
  update(photo: Photo): Observable<any> {
    return this.http.patch(this.store.config.apiPath + '/photo', photo);
  }

  /**
   * Delete a photo
   * @param id
   */
  delete(photo: Photo): Observable<any> {
    if (this.store.user && !this.store.user.is_full_admin) {
      return of(null);
    }

    if (isEmpty(this.store.packs)) {
      this.packs.get();
    }

    return this.http
      .delete(this.store.config.apiPath + '/photo/' + photo.id)
      .switchMap(async (data) => {
        if (this.store.packs[photo.pack_id]) {
          // Remove the photo from the pack in the store
          const index = this.store.packs[photo.pack_id].photos.indexOf(photo);
          this.store.packs[photo.pack_id].photos.splice(index, 1);
        }

        // Remove the photo from the store
        delete this.store.photos[photo.id];
        this.photosChanged.next();
        return of(data);
      });
  }

  getParams(options?: FeedOptions) {
    const params = {};
    params['id'] = options.id;
    params['search'] = options.search;
    params['offset'] = options.offset;
    params['limit'] = options.limit;
    return params;
  }

  /**
   *
   * @param options
   */
  urlParams(options: FeedOptions) {
    const params = this.getParams(options);
    // Convert params into string values for url
    if (params) {
      const paramsArray = [];
      for (const key in params) {
        if (params[key]) {
          paramsArray.push(key + '=' + params[key]);
        }
      }

      return paramsArray.length > 0 ? '?' + paramsArray.join('&') : '';
    }
  }

  /**
   * Load tags for a single photo
   * @param photo
   */
  getTags(photo: Photo): Observable<Tag[]> {
    return this.http
      .get<Tag[]>(this.store.config.apiPath + '/photo/tags/' + photo.id)
      .switchMap((tags) => {
        this.store.photos[photo.id].tags = tags;
        return of(tags);
      });
  }

  /**
   * Track a photo download
   * @param photo
   */
  trackDownload(pack: Pack, photo: Photo) {
    const packDetails = pack ?? photo.pack ?? packFactory();

    const data = {
      photo_path: photo.full_image_url,
      pack_name: packDetails.name,
      pack_id: packDetails.id,
      photo_id: photo.id,
      download_type: 'Photo',
      email: this.store.user.email,
    };
    // If we want to track the event in the database we can use the EventService and this will also fire a Segment event
    this.eventService.track('Downloaded photo(s)', data);

    if (this.store.algolia.recentQueryId) {
      this.insights.sendConversionEvent('Downloaded photo', photo.id);
    }
  }

  /**
   * Get the count of photos to human review
   * @param currentPhotoId
   */
  getForHumanReview(currentPhotoId?: number) {
    return this.http
      .get(this.store.config.apiPath + '/photo/human-review/' + currentPhotoId)
      .switchMap((data) => {
        this.store.humanReviewCount = data['count'];
        this.store.humanReviewNextId = data['next'] ? data['next']['id'] : '';
        return of(data);
      });
  }

  /**
   * Approve a photo from human review
   * @param photoId
   */
  approveHumanReview(photoId: number) {
    return this.http.post(this.store.config.apiPath + '/photo/human-review/', { id: photoId });
  }

  isNewForUser(date: string) {
    if (!this.store.user || !this.store.user['previous_seen_at'] || !date) {
      return false;
    }
    return moment(date).isAfter(moment(this.store.user['previous_seen_at']));
  }

  async getKeywordRankings(keyword: string) {
    try {
      const data = await this.http
        .get(this.store.config.apiPath + `/photo/rankings?keyword=${keyword}`)
        .toPromise();
      return data;
    } catch (error) {
      return false;
    }
  }

  public async getHighestScoringPhotos() {
    try {
      const data = await this.http
        .get(this.store.config.apiPath + '/photo/highest-scoring')
        .toPromise();
      return data;
    } catch (error) {
      return false;
    }
  }
}
