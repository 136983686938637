import { Component, OnInit } from '@angular/core';
import { AppService } from 'app/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { equalValidator } from 'app/shared/equal.validator';
import { AuthService } from 'app/auth/auth.service';
import { AnalyticsService } from 'app/core/analytics.service';
import { AppStore } from 'app/app-store.service';

@Component({
  selector: 'app-auth-reset-password',
  templateUrl: './auth-reset-password.component.html',
  styleUrls: ['./auth-reset-password.component.scss'],
})
export class AuthResetPasswordComponent implements OnInit {
  public form: FormGroup;
  public token: string;
  public email: string;
  public message: string;
  public loading: boolean;
  public showLoginLink: boolean;

  constructor(
    public analyticsService: AnalyticsService,
    public auth: AuthService,
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public store: AppStore
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      // Set the token from the route params
      this.token = params['token'];
      this.email = params['email'];
    });
    this.store.headerType = 'login';
    // Set up the form
    const passwordGroup = {
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, equalValidator('password')]],
    };
    this.form = this.formBuilder.group(passwordGroup);
    this.analyticsService.track('Viewed reset password');
  }

  /**
   * Reset the user's password on form submit
   */
  submit() {
    this.loading = true;
    this.message = '';
    this.showLoginLink = false;
    this.auth
      .resetPassword(
        this.token,
        this.email,
        this.form.value.password,
        this.form.value.confirmPassword
      )
      .subscribe(
        (res) => {
          this.loading = false;
          this.message = 'Your password has been set.';
          this.analyticsService.track('Reset password success', { email: this.email });
          this.showLoginLink = true;
        },
        (error) => {
          // Handle errors
          this.message = 'Your token is invalid or has already been used.';
          this.analyticsService.track('Reset password error', { error });
          this.loading = false;
          this.form.reset();
        }
      );
  }
}
