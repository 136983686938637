import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoComponent } from './photo.component';
import { PhotoService } from 'app/photo/photo.service';
import { TagModule } from 'app/tag/tag.module';
import { SharedModule } from 'app/shared/shared.module';
import { EventModule } from '../event/event.module';
import { SingleModule } from 'app/single/single.module';
import { PhotoRoutingModule } from 'app/photo/photo-routing.module';

@NgModule({
  imports: [CommonModule, EventModule, PhotoRoutingModule, TagModule, SharedModule, SingleModule],
  declarations: [PhotoComponent],
  providers: [PhotoService],
  exports: [],
})
export class PhotoModule {}
