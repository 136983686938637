<div class="login-wrap">
  <div class="inner">
    <h1>Inspiration is keystrokes away</h1>
    <form (ngSubmit)="login()" #loginForm="ngForm" class="row">
      <div class="input-wrap">
        <label for="email">(Email)</label>
        <input type="email" [(ngModel)]="email" name="email" required />
      </div>
      <div class="input-wrap">
        <label for="password">(Password)</label>
        <input type="password" [(ngModel)]="password" name="password" required />
      </div>
      <button type="submit" class="dts-button black-fill">Sign In</button>
      <div class="message" *ngIf="message">{{ message }}</div>
    </form>
    <a routerLink="/forgot-password" class="text-link">Forgot Password</a>
  </div>
</div>
<app-spinner *ngIf="loading"></app-spinner>
