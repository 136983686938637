import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from '../onboarding.service';
import { AppStore } from 'app/app-store.service';
import { AnalyticsService } from 'app/core/analytics.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-onboarding-setup',
  templateUrl: './onboarding-setup.component.html',
  styleUrls: ['./onboarding-setup.component.scss'],
})
export class OnboardingSetupComponent implements OnInit {
  constructor(
    private analyticsService: AnalyticsService,
    public onboardingService: OnboardingService,
    public store: AppStore,
    public router: Router,
    public location: Location
  ) {}

  ngOnInit() {
    this.onboardingService.load();
    this.store.onboarding.step = 'setup';
  }

  submitSelections() {
    this.onboardingService.save();
    this.analyticsService.track(
      'Onboarding step complete',
      this.onboardingService.getSubmitableData()
    );
    this.router.navigate(['/onboarding/license']);
  }

  toggleValue(e, type) {
    const value = e.target.value;
    const i = this.store.onboarding[type].indexOf(value);

    if (i >= 0) {
      this.store.onboarding[type].splice(i, 1);
    } else {
      this.store.onboarding[type].push(value);
    }
  }
}
