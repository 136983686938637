import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { VideoComponent } from './video.component';
import { SingleModule } from 'app/single/single.module';

@NgModule({
  imports: [CommonModule, SharedModule, SingleModule],
  declarations: [VideoComponent],
})
export class VideoModule {}
