<app-message></app-message>
<nav
  class="header"
  role="navigation"
  [ngClass]="{
    small: headerSmall,
    'scroll-hide': headerHide,
    login: store.headerType === 'login',
    condensed: store.feed === 'editorial' || !auth.loggedIn(),
    onboarding: store.headerType === 'onboarding'
  }"
  [style.background-color]="store.settings['bookmarks']['colors']['bck_color']"
>
  <div class="left">
    <a class="logo" [routerLink]="['/']" *ngIf="auth.loggedIn()">
      <img
        *ngIf="store.settings['bookmarks']['colors']['text_color'] != '#FFFFFF'"
        src="../../assets/images/dts-logo-black.svg"
        width="60"
        alt="(DTS)"
      />
      <img
        *ngIf="store.settings['bookmarks']['colors']['text_color'] == '#FFFFFF'"
        src="../../assets/images/dts-logo-white.svg"
        width="60"
        alt="(DTS)"
      />
    </a>
    <a class="logo" href="https://deathtothestockphoto.com" *ngIf="!auth.loggedIn()">
      <img src="../../assets/images/dts-logo-cond-black.svg" width="60" alt="DTS Icon Logo" />
    </a>
    <p
      *ngIf="store.user"
      data-cy="user-name"
      [style.color]="
        store.settings['bookmarks']['colors']['text_color']
          ? store.settings['bookmarks']['colors']['text_color']
          : null
      "
    >
      Hi {{ store.user.firstname }}
    </p>
  </div>
  <div class="right">
    <ul
      class="main-nav"
      *ngIf="auth.loggedIn()"
      [style.color]="
        store.settings['bookmarks']['colors']['text_color']
          ? store.settings['bookmarks']['colors']['text_color']
          : null
      "
    >
      <li *ngIf="auth.loggedIn() && store.user && store.user.is_admin">
        <a routerLink="/" [routerLinkActive]="['active']">Admin</a>
      </li>
      <li>
        <a
          data-cy="logout-button"
          class="dts-button"
          *ngIf="auth.loggedIn()"
          (click)="auth.logout()"
          routerLink="/login"
          >Logout</a
        >
      </li>
    </ul>
  </div>
</nav>
<div
  class="nav-spacer"
  id="top"
  [ngClass]="{
    condensed: store.feed == 'editorial',
    login: store.headerType == 'login'
  }"
  *ngIf="store.headerType != 'onboarding'"
></div>
