import { Injectable } from '@angular/core';
import { ExperimentService } from 'app/experiment/experiment.service';
import { AppStore } from 'app/app-store.service';
import { environment } from 'environments/environment';

@Injectable()
export class AnalyticsService {
  constructor(private experimentService: ExperimentService, private store: AppStore) {
    // Listen to the identify trigger on the store - prevents injection loop
    this.store.userIdentify.subscribe((user) => {
      if (user) {
        this.identify(user);
      }
    });

    // Hook for tracking experiment events
    this.experimentService.viewEvent.subscribe((val) => {
      if (val) {
        this.track(val['event'], val['options']);
      }
    });
  }

  track(event: string, props: object = {}) {
    const properties = {
      ...props,
      ...this.experimentService.appendActiveExperiments(),
    };
    if (typeof window !== 'undefined') {
      window['analytics'].track(event, properties);
    }
  }

  page() {
    if (typeof window !== 'undefined') {
      window['analytics'].page();
    }
  }

  /**
   * Call Segment analytics
   * @param appUser
   */
  identify(appUser) {
    const user = Object.assign({}, appUser);
    user.name = user.firstname;

    // Add the onboarding properties to the user for analytics
    user.core_values =
      user.properties && user.properties.find((prop) => prop['key'] == 'onboarding_core_values')
        ? user.properties.find((prop) => prop['key'] == 'onboarding_core_values')['value']
        : null;
    user.industry_options =
      user.properties &&
      user.properties.find((prop) => prop['key'] == 'onboarding_industry_options')
        ? user.properties.find((prop) => prop['key'] == 'onboarding_industry_options')['value']
        : null;
    user.moodboard_options =
      user.properties &&
      user.properties.find((prop) => prop['key'] == 'onboarding_moodboard_options')
        ? user.properties.find((prop) => prop['key'] == 'onboarding_moodboard_options')['value']
        : null;

    // Add the user trial properties to the user for analytics
    user.trial_started =
      user.properties && user.properties.find((prop) => prop['key'] == 'trial_started')
        ? user.properties.find((prop) => prop['key'] == 'trial_started')['value']
        : null;
    user.trial_ended =
      user.properties && user.properties.find((prop) => prop['key'] == 'trial_ended')
        ? user.properties.find((prop) => prop['key'] == 'trial_ended')['value']
        : null;

    user.role = user.billing_status;
    if (
      user.stripe_customer &&
      user.stripe_customer['subscriptions'] &&
      user.stripe_customer['subscriptions']['data'].length > 0
    ) {
      user.plan = user.stripe_customer['subscriptions']['data'][0]['plan']['id'];
      user.subscription_price = user.stripe_customer['subscriptions']['data'][0]['plan']['amount'];
    }

    // Only send through the role_updated_at timestamp if we have one
    if (user.role_updated_at == null) {
      delete user.role_updated_at;
    }

    delete user.firstname;
    delete user.billing_status;
    delete user.bookmarks;
    delete user.cards;
    delete user.addresses;
    delete user.customer_id;
    delete user.current_login_ip;
    delete user.last_login_ip;
    delete user.last_login_at;
    delete user.login_count;
    delete user.deleted_at;
    delete user.is_admin;
    delete user.stripe_customer;
    delete user.avatar_url;
    delete user.roles;
    delete user.password;
    delete user.properties;

    if (typeof window !== 'undefined') {
      window['analytics'].identify(user.id, user);
    }

    // Help scout beacon - init and identify
    if (typeof window['Beacon'] !== 'undefined') {
      window['Beacon']('init', environment.beacon);
      window['Beacon']('identify', user);
    }
  }
}
