import { AbstractControl } from '@angular/forms';
/**
 * This custom validator tests that 2 fields are the same
 * It can be used for things like password confirmation
 * @param match - a field in the form to match against
 */
export const equalValidator = (match: string) => {
  return (control: AbstractControl) => {
    const group = control.parent;
    if (group && group.get(match)) {
      const matchfield = group.get(match);
      if (matchfield && matchfield.value === control.value) {
        return null;
      }
    }
    return {
      validateMatch: {
        valid: false,
      },
    };
  };
};
