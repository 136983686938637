import {
  Component,
  OnInit,
  Input,
  OnChanges,
  HostListener,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PhotoService } from 'app/photo/photo.service';
import { AppService } from 'app/app.service';
import { AppStore } from 'app/app-store.service';
import { PackService } from 'app/pack/pack.service';
import { Tag } from 'app/tag/tag';
import { HttpClient } from '@angular/common/http';
import { BookmarksService } from 'app/bookmarks/bookmarks.service';
import { ArtistService } from 'app/admin/artist/artist.service';
import { AlgoliaInsightsService } from 'app/shared/algolia/insights.service';
import { EventService } from '../event/event.service';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss'],
})
export class SingleComponent implements OnInit, OnChanges {
  @ViewChild('left', { static: true }) left;

  @Input() item;
  @Input() pack;
  @Input() type = 'photo';
  public downloadRes = 1280;
  public tags: Array<Tag>;
  public isGif: boolean;
  public placeholderWidth: number;
  public placeholderHeight: number;
  public first: boolean;
  public last: boolean;
  public feed: boolean;
  public showImg: boolean;
  public bookmark_loading = false;
  public selectOptions = [
    { value: 1, display: 'Download For Print' },
    { value: 1280, display: 'Download For Web' },
    { value: 800, display: 'Download For Social' },
  ];
  public artist;
  public showDownloadAnimation = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode == 37 && this.canPrev()) {
      this.prev();
    }
    if (event.keyCode == 39 && this.canNext()) {
      this.next();
    }
    if (event.keyCode == 27) {
      this.app.goBack();
    }
  }

  constructor(
    public router: Router,
    public location: Location,
    public photoService: PhotoService,
    public packService: PackService,
    public store: AppStore,
    public app: AppService,
    private http: HttpClient,
    public bookmarksService: BookmarksService,
    public serviceArtist: ArtistService,
    public insights: AlgoliaInsightsService,
    private eventService: EventService
  ) {}

  ngOnInit() {
    const type = this.item.video_url ? 'video' : 'photo';
    const eventName = 'Viewed ' + type;

    if (this.store.trialMode) {
      this.downloadRes = 800;
    }

    const eventData = {
      pack_id: type == 'video' ? this.item.id : this.item.pack_id,
      photo_id: type == 'video' ? 0 : this.item.id,
    };
    this.eventService.track(eventName, eventData);

    if (this.store.algolia.recentQueryId) {
      // send data to algolia insights

      // send click event - cannot currently send click because it requires position and we shuffle position
      // const clickEventName = "Clicked " + type;
      // this.insights.sendClickEvent(clickEventName, this.item.id);

      // send view event
      this.insights.sendViewEvent(eventName, this.item.id);
    }
  }

  ngOnChanges() {
    this.showImg = false;

    this.first = this.item.first ? true : false;
    this.last = this.item.last ? true : false;
    this.feed = this.item.feed ? true : false;

    if (this.pack) {
      this.item.pack = this.pack;
    }

    if (this.item && !this.item.video_url) {
      // item is an image
      this.getPhoto(this.item.id);
    } else if (this.item && this.item.video_url) {
      // item is a video
      this.calcPlaceholderDim();

      this.serviceArtist.loadAllArtists().then((artists) => {
        this.artist = this.item.artist_id ? { ...this.store.artists[this.item.artist_id] } : null;
      });
    }

    if (this.item) {
      this.tags = this.item.tags;
      this.isGif =
        this.item.full_image_url && this.item.full_image_url.match('.gif') ? true : false;
      if (this.tags) {
        this.filterTags(this.tags);
      }
    }
  }

  changeRes(res) {
    this.downloadRes = res;
  }

  calcPlaceholderDim() {
    const width =
      this.item && this.item.dimensions
        ? this.item.dimensions.split('x')[0]
        : this.item.video_width;
    const height =
      this.item && this.item.dimensions
        ? this.item.dimensions.split('x')[1]
        : this.item.video_height;
    const ratio = width / height;
    if (ratio > 1) {
      this.placeholderWidth = 720;
      this.placeholderHeight = 720 / ratio;
    } else {
      this.placeholderWidth = 720 * ratio;
      this.placeholderHeight = 720;
    }
  }

  downloadClickedAnim() {
    let timeout = 5000;
    if (this.downloadRes == 1) {
      timeout = 18000;
    }
    if (this.downloadRes == 800) {
      timeout = 1000;
    }
    this.showDownloadAnimation = true;
    setTimeout(() => {
      this.showDownloadAnimation = false;
    }, timeout);
  }

  filterTags(tags) {
    return tags;
  }

  /**
   * Get all photo details
   * @param photoId
   */
  getPhoto(photoId) {
    this.photoService.get({ id: photoId }).subscribe((data) => {
      this.item = this.store.photos[photoId];

      this.serviceArtist.loadAllArtists().then((artists) => {
        this.artist = this.item.pack?.artist_id
          ? { ...this.store.artists[this.item.pack.artist_id] }
          : null;
      });

      if (this.item.pack?.additional_data) {
        if (typeof this.item.pack.additional_data === 'string') {
          this.item.pack.additional_data = JSON.parse(this.item.pack.additional_data);
        }
        const creativeDirector = this.item.pack.additional_data.creative_director;
        if (creativeDirector) {
          this.item.pack.creative_director_name = creativeDirector.name;
          this.item.pack.creative_director_instagram = creativeDirector.instagram;
        }
      }

      this.tags = this.item && this.item.tags ? this.filterTags(this.item.tags) : [];
      if (this.item && this.item.dimensions) {
        this.calcPlaceholderDim();
      }
    });
  }

  searchForTerm(term) {
    this.router.navigate(['/trending'], { queryParams: { search: term } });
  }

  caculatePlaceholderStyles(w, h) {
    if (this.left) {
      const wrapH = this.left.nativeElement.offsetHeight;
      const wrapW = this.left.nativeElement.offsetWidth;

      const maxW = (wrapH * w) / h;

      if (w > h && wrapW < maxW) {
        // landscape
        return { width: '100%', height: 0, paddingTop: (h / w) * 100 + '%' };
      } else {
        // portrait
        const width = Math.floor((wrapH * w) / h);
        return { width: width + 'px', height: '100%' };
      }
    }
  }

  canPrev() {
    return this.store.currentFeed &&
      (this.store.currentFeedIndex > 0 || this.store.currentFeedRow > 0)
      ? true
      : false;
  }

  canNext() {
    return this.store.currentFeed &&
      this.store.currentFeed[this.store.currentFeedRow] &&
      (this.store.currentFeed[this.store.currentFeedRow + 1] ||
        this.store.currentFeedIndex < this.store.currentFeed[this.store.currentFeedRow].length - 1)
      ? true
      : false;
  }

  next() {
    if (!this.canNext()) {
      return;
    }

    const currentRowLength = this.store.currentFeed[this.store.currentFeedRow].length;

    if (this.store.currentFeedIndex === currentRowLength - 1) {
      this.store.currentFeedIndex = 0;
      this.store.currentFeedRow++;
    } else {
      this.store.currentFeedIndex++;
    }

    const photo = this.store.currentFeed[this.store.currentFeedRow][this.store.currentFeedIndex];
    const path = photo.full_image_url ? '/photo' : '/video';
    this.router.navigate([path, photo.id], { replaceUrl: true });
  }

  prev() {
    if (!this.canPrev()) {
      return;
    }

    const previousRowLength = this.store.currentFeed[this.store.currentFeedRow - 1]
      ? this.store.currentFeed[this.store.currentFeedRow - 1].length
      : 0;

    if (this.store.currentFeedIndex === 0) {
      this.store.currentFeedIndex = previousRowLength - 1;
      this.store.currentFeedRow--;
    } else {
      this.store.currentFeedIndex--;
    }

    const photo = this.store.currentFeed[this.store.currentFeedRow][this.store.currentFeedIndex];
    const path = photo.full_image_url ? '/photo' : '/video';
    this.router.navigate([path, photo.id], { replaceUrl: true });
  }

  get is_bookmarked() {
    return this.bookmarksService.is_bookmarked(this.item);
  }

  add_bookmark() {
    this.bookmark_loading = true;

    this.bookmarksService.add_bookmark(this.item).subscribe((res) => {
      this.bookmark_loading = false;
    });
  }

  remove_bookmark() {
    this.bookmark_loading = true;

    this.bookmarksService.remove_bookmark(this.item).subscribe((res) => {
      this.bookmark_loading = false;
    });
  }
}
