import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FeedbackService {
  constructor(public http: HttpClient) {}

  send(data: any): Observable<any> {
    return this.http.post(environment.apiPath + '/feedback', data);
  }

  sendSearchRequest(data: any): Observable<any> {
    return this.http.post(environment.apiPath + '/feedback/search', data);
  }
}
