import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagService } from 'app/tag/tag.service';
import { TagFormComponent } from './tag-form/tag-form.component';
import { SharedModule } from 'app/shared/shared.module';
import { TagListComponent } from './tag-list/tag-list.component';
import { TagTabListComponent } from './tag-tab-list/tag-tab-list.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [TagFormComponent, TagListComponent, TagTabListComponent],
  providers: [TagService],
  exports: [TagFormComponent, TagListComponent, TagTabListComponent],
})
export class TagModule {}
