import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStore } from 'app/app-store.service';
import { AuthService } from 'app/auth/auth.service';
import { ExperimentService } from 'app/experiment/experiment.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() type;

  public lastScrollTop = 0;
  public headerSmall = false;
  public headerHide = false;

  constructor(
    public experimentService: ExperimentService,
    public store: AppStore,
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.experimentService.viewed('header_color');
  }

  ngOnInit() {
    this.type = this.type ? this.type : 'normal';
  }

  centerLogo() {
    const centerHeaderTypes = ['login'];
    return centerHeaderTypes.includes(this.store.headerType);
  }

  /**
   * Redirect the user to the main site when not logged in otherwise go to main feed
   */
  logoRedirect() {
    if (!this.auth.loggedIn() && typeof window != 'undefined') {
      window.location.href = 'https://deathtothestockphoto.com';
    } else {
      this.router.navigate(['/']);
    }
  }

  @HostListener('window:scroll', ['$event'])
  headerScroll(event) {
    const top = window.pageYOffset;

    // Set header small class if scrolled more than 100px down page
    this.headerSmall = top > 44 ? true : false;

    // Slide header up up and away if scrolled more than 150px and also scrolling downward
    if (top > 42) {
      this.headerHide = top > this.lastScrollTop ? true : false;
    }

    // Set previous scroll value to check against for scroll direction
    this.lastScrollTop = top;
  }
}
