<div class="card">
  <h1>
    <img src="../../../assets/images/dts-logo-black.svg" width="60" height="19" />
    {{ title }}
  </h1>
  <div class="priceContainer">
    <div class="price" *ngIf="plan.id && plan.id != 'freeForever'">
      <span>${{ price }}</span>
      <span class="period">/{{ plan.period == 'monthly' ? 'm' : 'y' }}</span>
    </div>
    <div class="discountText" *ngIf="discountText">
      {{ discountText }}
    </div>
  </div>
  <div class="price" *ngIf="!plan.id">Free</div>
  <h2 *ngIf="!plan.id">14 day trial</h2>
  <h2 *ngIf="plan.id && plan.id != 'freeForever'">
    {{ billing }}
    <!-- <span *ngIf="location == 'upgrade-modal'"> (switch to <a>{{(plan.period == 'month') ? 'annual' : 'monthly'}}</a>)</span> -->
  </h2>
  <div class="type">{{ license }}</div>
  <ul *ngIf="plan.id && plan.id != 'freeForever'">
    <li>
      <div class="icon tick"></div>
      All Resolutions
    </li>
    <li>
      <div class="icon tick"></div>
      Editorial Photos
    </li>
    <li>
      <div class="icon tick"></div>
      Photo Packs
    </li>
    <li>
      <div class="icon tick"></div>
      Video Packs
    </li>
  </ul>
  <ul *ngIf="!plan.id">
    <li class="str">
      <div class="icon"></div>
      All Resolutions
    </li>
    <li class="str">
      <div class="icon"></div>
      Editorial Photos
    </li>
    <li class="str">
      <div class="icon"></div>
      Photo Packs
    </li>
    <li class="str">
      <div class="icon"></div>
      Video Packs
    </li>
  </ul>
  <div
    class="dts-button gradient-fill"
    (click)="activatePlan()"
    *ngIf="
      plan.id &&
      (store.trialMode || !currPlan) &&
      plan.id != 'plan_DiUzSZpcasS5mx' &&
      plan.id != 'freeForever' &&
      !cardFailed
    "
  >
    ACTIVATE PLAN 💰
  </div>

  <div class="error message" *ngIf="cardFailed">{{ errorMessage }}</div>

  <a class="dts-button black-fill" *ngIf="cardFailed" href="/account/billing"
    >update billing details</a
  >

  <a
    href="mailto:shaun@deathtostock.com?subject=I am interested in partnering with Death to Stock"
    class="dts-button black-fill"
    *ngIf="plan.id && plan.id == 'plan_DiUzSZpcasS5mx' && !currPlan"
    >Request Access</a
  >
  <div class="loading" *ngIf="loading" [ngClass]="{ white: plan.id === 'freeForever' }">
    <app-spinner></app-spinner>
  </div>
</div>
