import { Component, OnInit } from '@angular/core';
import { AppStore } from 'app/app-store.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
})
export class OnboardingComponent implements OnInit {
  constructor(public store: AppStore) {}

  ngOnInit() {
    this.store.headerType = 'onboarding';
  }

  ngOnDestroy() {
    delete this.store.headerType;
  }
}
