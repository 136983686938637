let emoji = ['🙄'];
const circles = [];
const emojiCount = 10;
const loopDelay = 2000;
let started = false;

let container = null;

function startUp() {
  for (let i = 0; i < emojiCount; i++) {
    addCircle(i * loopDelay, [10 + 0, 300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * loopDelay, [10 + 0, -300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * loopDelay, [10 - 200, -300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * loopDelay, [10 + 200, 300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * loopDelay, [10 - 400, -300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * loopDelay, [10 + 400, 300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * loopDelay, [10 - 600, -300], emoji[Math.floor(Math.random() * emoji.length)]);
    addCircle(i * loopDelay, [10 + 600, 300], emoji[Math.floor(Math.random() * emoji.length)]);
  }
  started = true;
}

function addCircle(delay, range, color) {
  setTimeout(function () {
    const c = new Circle(
      range[0] + Math.random() * range[1],
      80 + Math.random() * 4,
      color,
      {
        x: -0.15 + Math.random() * 0.3,
        y: 1 + Math.random() * 1,
      },
      range
    );
    circles.push(c);
  }, delay);
}

function Circle(x, y, c, v, range) {
  const _this = this;
  this.x = x;
  this.y = y;
  this.color = c;
  this.v = v;
  this.range = range;
  this.element = document.createElement('span');
  this.element.innerHTML = c;
  if (container) {
    container.appendChild(this.element);
  }

  this.update = function () {
    if (_this.y > window.innerHeight + 200) {
      _this.y = 80 + Math.random() * 4;
      _this.x = _this.range[0] + Math.random() * _this.range[1];
    }
    _this.y += _this.v.y;
    _this.x += _this.v.x;
    this.element.style.opacity = 1;
    this.element.style.transform = 'translate3d(' + _this.x + 'px, ' + _this.y + 'px, 0px)';
    this.element.style.webkitTransform = 'translate3d(' + _this.x + 'px, ' + _this.y + 'px, 0px)';
    this.element.style.mozTransform = 'translate3d(' + _this.x + 'px, ' + _this.y + 'px, 0px)';
  };
}

function animateOne() {
  container = document.getElementById('animate');
  for (const g in circles) {
    circles[g].update();
  }
  requestAnimationFrame(animateOne);
}

export default function startRain(emojiArray: any) {
  if (started) {
    return;
  }

  emoji = emojiArray;
  startUp();
  animateOne();
}
