import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnboardingWelcomeComponent } from './welcome/onboarding-welcome.component';
import { OnboardingComponent } from './onboarding.component';
import { OnboardingSetupComponent } from './setup/onboarding-setup.component';
import { OnboardingLicenseComponent } from './license/onboarding-license.component';
import { OnboardingSurveyComponent } from './survey/onboarding-survey.component';

const routes: Routes = [
  {
    path: '',
    component: OnboardingComponent,
    children: [
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full',
      },
      {
        path: 'welcome',
        component: OnboardingWelcomeComponent,
      },
      {
        path: 'setup',
        component: OnboardingSetupComponent,
      },
      {
        path: 'license',
        component: OnboardingLicenseComponent,
      },
      {
        path: 'survey',
        component: OnboardingSurveyComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OnboardingRoutingModule {}
