import { Injectable } from '@angular/core';
import { AppStore } from '../../app-store.service';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AlgoliaInsightsService {
  private options;
  private insightsUrl = 'https://insights.algolia.io/1/events';

  constructor(private store: AppStore, public http: HttpClient) {
    this.options = {
      headers: new HttpHeaders({
        'x-algolia-api-key': environment.algoliaAppKey,
        'x-algolia-application-id': environment.algoliaApp,
        'Content-Type': 'application/json',
      }),
    };
  }

  getQueryId() {
    if (this.store.algolia.recentQueryId.length) {
      return this.store.algolia.recentQueryId;
    }
    return null;
  }

  getUserId() {
    return this.store.currentUserId.toString();
  }

  async sendClickEvent(eventName, objectId) {
    const data = {
      eventType: 'click',
      eventName: eventName,
      index: 'photos_created_desc',
      userToken: this.getUserId(),
      queryID: this.getQueryId(),
      objectIDs: [objectId.toString()],
    };

    try {
      const res = await this.http
        .post(this.insightsUrl, { events: [data] }, this.options)
        .toPromise();
    } catch (error) {}
  }

  async sendViewEvent(eventName, objectId) {
    const data = {
      eventType: 'view',
      eventName: eventName,
      index: 'photos_created_desc',
      userToken: this.getUserId(),
      queryID: this.getQueryId(),
      objectIDs: [objectId.toString()],
    };

    try {
      const res = await this.http
        .post(this.insightsUrl, { events: [data] }, this.options)
        .toPromise();
    } catch (error) {}
  }

  async sendConversionEvent(eventName, objectId) {
    const data = {
      eventType: 'conversion',
      eventName: eventName,
      index: 'photos_created_desc',
      userToken: this.getUserId(),
      queryID: this.getQueryId(),
      objectIDs: [objectId.toString()],
    };

    try {
      const res = await this.http
        .post(this.insightsUrl, { events: [data] }, this.options)
        .toPromise();
    } catch (error) {}
  }
}
