<div>
  <ng-select
    class="custom"
    [(ngModel)]="model"
    [clearable]="false"
    [searchable]="false"
    (change)="handleChange($event)"
  >
    <ng-option *ngFor="let option of selectOptions" [value]="option.value">{{
      option.display
    }}</ng-option>
  </ng-select>
</div>
