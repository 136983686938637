import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExperimentDirective } from './experiment.directive';
import { ExperimentService } from './experiment.service';

@NgModule({
  imports: [CommonModule],
  declarations: [ExperimentDirective],
  exports: [ExperimentDirective],
  providers: [ExperimentService],
})
export class ExperimentModule {}
