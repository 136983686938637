export const environment = {
  production: true,
  name: 'production',
  buildType: 'production',
  webPath: 'https://admin.deathtothestockphoto.com',
  apiPath: 'https://api.deathtothestockphoto.com/api',
  stripePublishableKey: 'pk_live_woyc4y3CCFXuvydgTWPEY0vF',
  algoliaApp: '4ZUYI4T1E7',
  algoliaAppKey: 'ab8b9901414944cef1e44a240c9dd908',
  s3Bucket: 'https://s3.amazonaws.com/deathtostock',
  release: '%RELEASE%',
  imgix_token: 'zZkesqwbqCRDX9nR',
  beacon: '8fbe84dd-4eb9-4f1a-b4fa-4a8a6c02e940',
};
