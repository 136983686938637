import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'app/header/header.component';
import { RouterModule } from '@angular/router';
import { SearchModule } from 'app/search/search.module';
import { SharedModule } from 'app/shared/shared.module';
import { MessageModule } from 'app/message/message.module';
import { TagModule } from 'app/tag/tag.module';
import { ExperimentModule } from 'app/experiment/experiment.module';

@NgModule({
  imports: [
    CommonModule,
    ExperimentModule,
    RouterModule,
    SearchModule,
    SharedModule,
    MessageModule,
    TagModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
