<div class="page">
  <div class="inner">
    <h1>
      WELCOME To the tribe<span *ngIf="data && data['firstname']"> {{ data['firstname'] }}</span
      ><span *ngIf="!data && store.user && store.user.firstname"> {{ store.user.firstname }}</span
      >!
    </h1>
    <p>
      We appreciate you trusting us with your brand and projects. Lets get your media library
      set-up.
    </p>
    <div class="dts-button gradient-fill" (click)="setWelcome()">LETS GOOOO</div>
  </div>
  <div class="emoji-rain-wrapper">
    <div class="emoji-rain" id="container">
      <div class="animate" id="animate"></div>
    </div>
  </div>
</div>
