import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { PackService } from 'app/pack/pack.service';
import { PhotoService } from 'app/photo/photo.service';
import { UserService } from 'app/user/user.service';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { AppStore } from 'app/app-store.service';
import { CookieService } from './core/cookie.service';
import { ExperimentService } from './experiment/experiment.service';
import { AnalyticsService } from './core/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public resizeTimer: any;

  @HostListener('window:resize')
  onResize() {
    this.resizing();
  }

  constructor(
    public analyticsService: AnalyticsService,
    public auth: AuthService,
    public cookieService: CookieService,
    public experimentService: ExperimentService,
    public pack: PackService,
    public photo: PhotoService,
    public route: ActivatedRoute,
    public router: Router,
    public store: AppStore,
    public user: UserService
  ) {
    this.cookieService.data = document.cookie;
  }

  ngOnInit() {
    // Init the experiment service once the cookie has been parsed
    this.experimentService.init();
    this.route.queryParams.subscribe((queryParams: Params) => {
      this.store.searchTerm = queryParams['search'];
    });

    // Track page views
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // this.analyticsService.page();
        // clear recent search queryId after moving away from search
        if (
          !val.url.includes('/photo/') &&
          !val.url.includes('/trending') &&
          !val.url.includes('/video/')
        ) {
          this.store.algolia = {
            recentQueryId: null,
            searchTerm: null,
          };
          // this.store.algolia.recentQueryId = null;
          // this.store.algolia.searchTerm = null;
        }
      }
    });
  }

  /**
   * Only fire window resize event once
   */
  resizing() {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.store.windowResizeFinished.next(true);
    }, 500);
  }
}
