import { Injectable } from '@angular/core';
import { Pack } from 'app/pack/pack';
import { AppStore } from 'app/app-store.service';
import { Observable } from 'rxjs/Observable';
import { of, Subject } from 'rxjs';
import { Photo } from 'app/photo/photo';
import { Router } from '@angular/router';
import { FeedOptions } from 'app/shared/feed-options';
import { MessageService } from 'app/message/message.service';
import { HttpClient, HttpEventType, HttpEvent, HttpRequest } from '@angular/common/http';
import { EventService } from '../event/event.service';
import { AnalyticsService } from 'app/core/analytics.service';
import { ArtistService } from 'app/admin/artist/artist.service';
import { AlgoliaInsightsService } from '../shared/algolia/insights.service';

@Injectable()
export class PackService {
  public loadingPacks = false;
  public feedEnd = false;
  public packsChanged = new Subject();

  constructor(
    public analyticsService: AnalyticsService,
    private eventService: EventService,
    private router: Router,
    private store: AppStore,
    private http: HttpClient,
    private artistService: ArtistService,
    public messageService: MessageService,
    public insights: AlgoliaInsightsService
  ) {}

  /**
   *  Remove Featured Image
   * @param pack  Pack
   * @return pack Pack
   */
  removeFeaturedImage(pack: Pack): Pack {
    pack.featured_photo_id = null;
    pack.featured_photo = null;
    this.http
      .patch(this.store.config.apiPath + '/pack/set-featured', {
        pack_id: pack.id,
        photo_id: 0,
      })
      .subscribe(
        (res) => {},
        (error) => {
          this.messageService.error('Oops something went wrong removing the featured photo.');
        }
      );
    return pack;
  }

  /**
   * Set Featured Image
   * @param pack Pack
   * @param photo Photo
   * @return pack: Pack
   */
  setFeaturedImage(pack: Pack, photo: Photo): Pack {
    pack.featured_photo_id = photo.id;
    pack.featured_photo = photo;
    this.http
      .patch(this.store.config.apiPath + '/admin/project/set-featured', {
        pack_id: pack.id,
        photo_id: photo.id,
      })
      .subscribe(
        (res) => {},
        (error) => {
          this.messageService.error('Oops something went wrong setting this photo as the feature.');
        }
      );
    return pack;
  }

  /**
   *  Remove Related Image
   * @param pack  Pack
   * @return pack Pack
   */
  removeRelatedImage(pack: Pack): Pack {
    pack.related_photo_id = null;
    this.http
      .patch(this.store.config.apiPath + '/pack/set-related', {
        pack_id: pack.id,
        photo_id: 0,
      })
      .subscribe(
        (res) => {},
        (error) => {
          this.messageService.error('Oops something went wrong removing the related photo.');
        }
      );
    return pack;
  }

  /**
   * Set related Image
   * @param pack Pack
   * @param photo Photo
   * @return pack: Pack
   */
  setRelatedImage(pack: Pack, photo: Photo): Pack {
    pack.related_photo_id = photo.id;
    this.http
      .patch(this.store.config.apiPath + '/pack/set-related', {
        pack_id: pack.id,
        photo_id: photo.id,
      })
      .subscribe(
        (res) => {},
        (error) => {
          this.messageService.error(
            'Oops something went wrong setting this photo as the related photo.'
          );
        }
      );
    return pack;
  }

  /**
   * Publish a pack
   * @param pack
   */
  publish(pack: Pack): Observable<Pack> {
    if (this.store.user && !this.store.user.is_full_admin) {
      return of(null);
    }
    return this.http
      .patch<Pack>(this.store.config.apiPath + '/admin/project/publish', {id: pack.id})
      .switchMap((res) => {
        this.store.packs[pack.id].published_on = res['published_on'];
        return of(res);
      });
  }
  
  /**
   * Unpublish a pack
   * @param pack
   */
  unpublish(pack: Pack): Observable<Pack> {
    if (this.store.user && !this.store.user.is_full_admin) {
      return of(null);
    }
    return this.http.patch<Pack>(this.store.config.apiPath + '/admin/project/unpublish', {id: pack.id})
    .switchMap((res) => {
        this.store.packs[pack.id].published_on = res['published_on'];
        return of(res);
      });
  }

  /**
   * Feature a pack
   * @param pack
   */
  feature(pack: Pack): Observable<Pack> {
    if (this.store.user && !this.store.user.is_admin) {
      return of(null);
    }
    return this.http.patch<Pack>(this.store.config.apiPath + '/pack/feature', {
      id: pack.id,
    });
  }

  /**
   * Unfeature a pack
   * @param pack
   */
  unfeature(pack: Pack): Observable<Pack> {
    if (this.store.user && !this.store.user.is_admin) {
      return of(null);
    }
    return this.http
      .patch<Pack>(this.store.config.apiPath + '/pack/unfeature', {
        id: pack.id,
      })
      .switchMap((res) => {
        return of(res);
      });
  }

  /**
   * Hide a pack
   *
   * Call the backend pack hide endpoint to update the database,
   * and sync the store pack and artist's pack with the new update.
   *
   * @param pack The current pack to update
   * @param hide Update the hidden attribute with the given value
   */
  hide(pack: Pack, hide: boolean): Observable<Pack> {
    if (this.store.user && !this.store.user.is_admin) {
      return of(null);
    }

    const result = this.http.patch<Pack>(
      this.store.config.apiPath + '/admin/project/setFieldValue',
      {
        id: pack.id,
        key: 'hidden',
        value: hide,
      }
    );

    return result;
  }

  /**
   * Create a pack
   * @param pack Pack - the pack data to create
   * @return Observable
   */
  create(pack: Pack): Observable<any> {
    return this.http
      .post<Pack>(this.store.config.apiPath + '/admin/project', pack)
      .switchMap((data) => {
        this.store.packs[data.id] = data;
        return of(data);
      });
  }

  /**
   * Add a zip file to a pack
   * @param data FormData - containing the file to upload
   * @param pack Pack - the pack data to create
   * @return Observable
   */
  addZip(data: FormData, pack: Pack): Observable<any> {
    const request = new HttpRequest(
      'POST',
      this.store.config.apiPath + '/admin/project/add-zip',
      data,
      { reportProgress: true }
    );
    const response = this.http.request(request);
    return response.switchMap((res) => {
      if (res.type == HttpEventType.Response) {
        const data = res.body;
        pack.zip_file = data['path'];
        this.store.packs[pack.id].zip_file = data['path'];
      }
      return of(res);
    });
  }

  /**
   * Delete zip file and remove url from a pack
   * @param pack Pack - the pack that we want to delete the zip file from
   */
  deleteZip(pack: Pack): Observable<any> {
    return this.http
      .delete(this.store.config.apiPath + '/pack/delete-zip/' + pack.id)
      .switchMap((data) => {
        pack.zip_file = null;
        this.store.packs[pack.id].zip_file = null;
        return of(data);
      });
  }

  /**
   * Update a pack
   * @param pack Pack - the pack data to update
   * @return Observable
   */
  update(pack: Pack): Observable<any> {
    return this.http.patch(this.store.config.apiPath + '/admin/project', pack).switchMap((res) => {
      this.get().subscribe((res) => {});
      return of(res);
    });
  }

  async getPacksFromIds(requestIds: number[]) {
    this.loadingPacks = true;

    const data = await this.http
      .post<Pack[]>(this.store.config.apiPath + '/pack/ids/', {
        ids: requestIds,
        options: { limit: 1000 },
      })
      .toPromise();
    data.map((pack) => {
      this.store.packs[pack.id] = pack;
    });

    this.loadingPacks = false;
  }

  /**
   * Get packs and set them on the store
   * @return Observable
   */
  get(options?: FeedOptions): Observable<number[]> {
    this.loadingPacks = true;
    const ids = [];

    options = options ? options : {};
    // Build the endpoint
    const endpoint = '/projects';
    // endpoint += this.urlParams(options);
    // If we don't have packs in the store or a search then make a request for them
    return this.http
      .post<Pack[]>(this.store.config.apiPath + endpoint, { withPhotos: true, ...options })
      .switchMap((packs) => {
        // Map the reponse to the store and search ids
        packs.map((pack) => {
          this.store.packs[pack.id] = pack;

          if (pack.additional_data) {
            pack.additional_data = JSON.parse(pack.additional_data);
          }

          pack.photos.map((photo) => (this.store.photos[photo.id] = photo));
          ids.push(pack.id);
        });

        this.loadingPacks = false;
        // Dont reverse when loading
        return of(ids);
      });
  }

  /**
   * Delete a pack
   * @param id
   */
  delete(pack: Pack): Observable<any> {
    if (this.store.user && !this.store.user.is_full_admin) {
      return of(null);
    }
    return this.http
      .delete(this.store.config.apiPath + '/admin/project/' + pack.id)
      .switchMap((data) => {
        // Clear all the pack photos from the store
        for (const photo of pack.photos) {
          delete this.store.photos[photo.id];
        }
        // Clear the pack from the store
        delete this.store.packs[pack.id];
        return of(data);
      });
  }

  getParams(options?: FeedOptions) {
    const params = {};
    params['admin'] = options.admin;
    params['search'] = options.search;
    params['offset'] = options.offset;
    params['limit'] = options.limit;
    params['type'] = options.type;
    params['status'] = options.status;
    params['id'] = options.id;
    params['artist_id'] = options.artist_id;
    params['showHidden'] = options.showHidden;
    return params;
  }

  /**
   *
   * @param options
   */
  urlParams(options: FeedOptions) {
    const params = this.getParams(options);
    // Convert params into string values for url
    if (params) {
      const paramsArray = [];
      for (const key in params) {
        if (params[key]) {
          paramsArray.push(key + '=' + params[key]);
        }
      }
      return paramsArray.length > 0 ? '?' + paramsArray.join('&') : '';
    }
  }

  /**
   * Track a pack download
   * @param pack Pack
   */
  trackDownload(pack: Pack) {
    const data = {
      pack_name: pack.name,
      pack_id: pack.id,
      download_type: pack.video_url ? 'Video' : 'Photo Pack',
      email: this.store.user.email,
      total_photos: pack.video_url ? 0 : pack.photos.length,
    };

    // If we want to track the event in the database we can use the EventService and this will also fire a Segment event
    this.eventService.track(`Downloaded ${data.download_type}`, data);

    if (this.store.algolia.recentQueryId && data.download_type == 'Video') {
      this.insights.sendConversionEvent('Downloaded video', pack.id);
    }
  }

  /**
   * Track a pack view
   * @param pack Pack
   */
  trackView(pack: Pack) {
    const data = { pack_name: pack.name, email: this.store.user.email };
    this.analyticsService.track('Viewed photopack', data);
  }

  /**
   * Get packs and set them on the store
   * @return Observable
   */
  index(options?: FeedOptions): Observable<Pack[]> {
    this.loadingPacks = true;
    options = options ? options : {};
    // Build the endpoint
    let endpoint = '/projects/';
    endpoint += this.urlParams(options);

    // If we don't have packs in the store or a search then make a request for them
    return this.http.post<Pack[]>(this.store.config.apiPath + endpoint, { ...options });
  }

  /**
   * Create a new relationship between packs
   */
  addRelationship(packId: number, relatedPackId: number) {
    return this.http.post(this.store.config.apiPath + '/admin/project/add-relationship', {
      id: packId,
      related_pack_id: relatedPackId,
    });
  }

  /**
   * Remove a relationship between packs
   */
  removeRelationship(packId: number, relatedPackId: number) {
    return this.http.post(this.store.config.apiPath + '/admin/project/remove-relationship', {
      id: packId,
      related_pack_id: relatedPackId,
    });
  }

  setNextDrop(pack: Pack, data: string) {
    const result = this.http.patch<Pack>(
      this.store.config.apiPath + '/admin/project/setFieldValue',
      {
        id: pack.id,
        key: 'next_drop',
        value: data,
      }
    );

    return result;
  }
}
