import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Location, PlatformLocation } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { AppStore } from './app-store.service';

@Injectable()
export class AppService {
  private entryPage = true;
  constructor(
    public sanitizer: DomSanitizer,
    public router: Router,
    private location: Location,
    private platformLocation: PlatformLocation,
    public store: AppStore
  ) {
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        this.entryPage = event.id == 1; // router navigationId (goes up even when you go back)
      });

    this.location.subscribe((e: PopStateEvent) => {
      setTimeout(() => {
        // popstart fires before router events so need to set a delay to override naviagtionId
        this.entryPage = e.state.navigationId == 1; // browser navigationId (goes up when you go forward and down when you go back)
      }, 100);
    });
  }

  vimeoEmbedURL(url, bg: boolean = true) {
    const params = bg ? '?background=1&loop=1' : '?autoplay=1&loop=1';
    return url
      ? 'https://player.vimeo.com/video/' + url.replace('https://vimeo.com/', '') + params
      : '';
  }

  goBack(path = ''): void {
    if (!this.entryPage) {
      this.location.back();
    } else {
      this.router.navigate([path]);
    }
  }

  toggleTrialUpgradeModal(location?) {
    this.store.trialUpgradeModal = location ? location : false;
    this.store.trialUpgradeLocation = location ? location : '';
  }
}
