export interface OnboardingSelectable {
  slug: string;
  title: string;
}

export interface OnboardingState {
  coreValues: string[];
  defaultCoreValues: OnboardingSelectable[];
  defaultIndustryValues: OnboardingSelectable[];
  defaultMoodBoardOptions: OnboardingSelectable[];
  industryOptions: string[];
  letsGo: boolean;
  license: boolean;
  moodBoardOptions: string[];
  step: string;
  survey: string[];
}

export function onboardingStateFactory(): OnboardingState {
  return {
    coreValues: [],
    defaultCoreValues: [
      { slug: 'community', title: 'community' },
      { slug: 'trust', title: 'trust' },
      { slug: 'authenticity', title: 'authenticity' },
      { slug: 'dream', title: 'dream' },
      { slug: 'having-fun', title: 'having fun' },
      { slug: 'fresh', title: 'fresh' },
      { slug: 'adventure', title: 'adventure' },
      { slug: 'safety', title: 'safety' },
      { slug: 'well-being', title: 'well-being' },
    ],
    defaultIndustryValues: [
      { slug: 'lifestyle', title: 'lifestyle' },
      { slug: 'software', title: 'software' },
      { slug: 'retail', title: 'retail' },
      { slug: 'food-drink', title: 'food & drink' },
      { slug: 'design', title: 'design' },
      { slug: 'ecommerce', title: 'ecommerce' },
      { slug: 'media', title: 'media' },
      { slug: 'corporate', title: 'corporate' },
      { slug: 'health', title: 'health' },
    ],
    defaultMoodBoardOptions: [
      { slug: 'hype', title: 'hype' },
      { slug: 'dream', title: 'dream' },
      { slug: 'craft', title: 'craft' },
      { slug: 'play', title: 'play' },
    ],
    survey: [],
    industryOptions: [],
    letsGo: false,
    license: false,
    moodBoardOptions: [],
    step: 'welcome',
  };
}
