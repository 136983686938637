import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PhotoComponent } from './photo.component';
import { AuthGuard } from 'app/auth/auth.guard';
import { AuthActiveGuard } from 'app/auth/auth-active.guard';

const routes: Routes = [
  {
    path: 'photo/:id',
    component: PhotoComponent,
    canActivate: [AuthGuard, AuthActiveGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PhotoRoutingModule {}
