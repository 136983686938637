import { Component, OnInit } from '@angular/core';
import { AppStore } from 'app/app-store.service';
import { OnboardingService } from '../onboarding.service';
import { Router } from '@angular/router';
import { AnalyticsService } from 'app/core/analytics.service';
import { ExperimentService } from 'app/experiment/experiment.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-onboarding-license',
  templateUrl: './onboarding-license.component.html',
  styleUrls: ['./onboarding-license.component.scss'],
})
export class OnboardingLicenseComponent implements OnInit {
  public loading: boolean;
  public data: object;
  public notReady: boolean;

  constructor(
    private analyticsService: AnalyticsService,
    private experimentService: ExperimentService,
    public onboardingService: OnboardingService,
    public store: AppStore,
    public router: Router,
    public location: Location
  ) {}

  ngOnInit() {
    this.onboardingService.load();
    this.store.onboarding.step = 'license';
    this.data = JSON.parse(localStorage.getItem('signup'));
  }

  agreeToLicense() {
    this.loading = true;
    this.store.onboarding.license = true;
    this.onboardingService.save();
    this.analyticsService.track(
      'Onboarding step complete',
      this.onboardingService.getSubmitableData()
    );
    this.router.navigate(['/onboarding/survey']);
  }
}
