import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { AppStore } from 'app/app-store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AnalyticsService } from 'app/core/analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loading: boolean;
  public email: string;
  public password: string;
  public message = '';

  constructor(
    public analyticsService: AnalyticsService,
    public auth: AuthService,
    public http: HttpClient,
    public store: AppStore,
    public router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.analyticsService.track('Viewed login');
    this.store.headerType = 'login';
    this.store.feed == '';
    if (this.auth.loggedIn()) {
      this.router.navigate(['/']);
    }

    // If an email is supplied in the params then prefill the form
    this.route.params.subscribe((params) => {
      if (params['email']) {
        this.email = params['email'];
      }
    });
  }

  /*
   * Send a request to the server to log the user in
   */

  login() {
    // Set credentials object
    const creds = {
      email: this.email,
      password: this.password,
    };
    this.loading = true;
    this.message = '';
    this.auth.login(creds).subscribe(
      (res) => {
        this.email = '';
        this.password = '';
        this.message = 'You are logged in!';
        this.loading = false;

        this.router.navigate(['/']);
      },
      (err) => {
        console.log({ err });

        // Login failed - unauthorized
        this.loading = false;
        let message = 'Oops something went wrong.';
        if ((err && err.status === 401) || err.status === 403) {
          message = 'Incorrect email or password';
          this.password = '';
        }
        this.message = message;
      }
    );
  }

  /*
   * Log the user out by removing the token from storage
   */
  logout() {
    this.auth.logout();
  }

  ngOnDestroy() {
    delete this.store.headerType;
  }
}
