import { Component, OnInit } from '@angular/core';
import { AppStore } from 'app/app-store.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { AnalyticsService } from 'app/core/analytics.service';

@Component({
  selector: 'app-auth-forgot-pasword',
  templateUrl: './auth-forgot-pasword.component.html',
  styleUrls: ['./auth-forgot-pasword.component.scss'],
})
export class AuthForgotPaswordComponent implements OnInit {
  public loading: boolean;
  public form: FormGroup;
  public message = '';

  constructor(
    public analyticsService: AnalyticsService,
    public auth: AuthService,
    public router: Router,
    public store: AppStore,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.analyticsService.track('Viewed forgot password');
    this.store.headerType = 'login';
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  /**
   *  Need to write the function to reset the password now
   */
  resetPassword() {
    this.loading = true;

    this.message = '';
    this.auth.sendPasswordReset(this.form.value.email).subscribe(
      (res) => {
        this.loading = false;
        this.analyticsService.track('Forgot password success', {
          email: this.form.value.email,
        });
        this.message = 'Password reset email successfully sent. Check your email.';
      },
      (error) => {
        // Handle errors
        this.analyticsService.track('Forgot password error', { error });
        this.message = "Invalid email address or user email doesn't exist.";
        this.loading = false;
      }
    );
  }

  /**
   * Remove header type on component destroy
   */
  ngOnDestroy() {
    delete this.store.headerType;
  }
}
