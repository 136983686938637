import { Injectable, HostListener } from '@angular/core';
import { AppStore } from 'app/app-store.service';
import { Observable } from 'rxjs/Observable';
// import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AlgoliaInsightsService } from '../shared/algolia/insights.service';
import { EventService } from '../event/event.service';

@Injectable({
  providedIn: 'root',
})
export class BookmarksService {
  constructor(
    private store: AppStore,
    private http: HttpClient,
    public insights: AlgoliaInsightsService,
    private eventService: EventService
  ) {}

  add_bookmark(item) {
    let type = 'photo';
    if (item.type == 'video' || item.video_url) {
      type = 'video';
    }
    const data = {
      type: type,
      item_id: item.id,
    };

    const eventName = 'Bookmarked ' + type;

    const eventData = {
      pack_id: type == 'video' ? item.id : item.pack_id,
      photo_id: type == 'video' ? 0 : item.id,
    };
    this.eventService.track(eventName, eventData);

    // Send Algolia insights
    if (this.store.algolia.recentQueryId) {
      this.insights.sendConversionEvent(eventName, item.id);
    }

    return this.http.patch(this.store.config.apiPath + '/bookmark/add/', data).map((res) => {
      this.store.user.bookmarks = res;
      this.store.bookmark_change.next(true); // fire event so anyy subs can react
      return res;
    });
  }

  remove_bookmark(item) {
    let type = 'photo';
    if (item.type == 'video' || item.video_url) {
      type = 'video';
    }
    const data = {
      type: type,
      item_id: item.id,
    };
    return this.http.patch(this.store.config.apiPath + '/bookmark/remove/', data).map((res) => {
      this.store.user.bookmarks = res;
      this.store.bookmark_change.next(true); // fire event so anyy subs can react
      return res;
    });
  }

  is_bookmarked(item) {
    let bookmarked = false;

    if (!this.store?.user?.bookmarks) {
      return bookmarked;
    }

    if (item.type == 'video' || item.video_url) {
      this.store.user.bookmarks['videos'].forEach((video) => {
        if (video.id == item.id) {
          bookmarked = true;
        }
      });
    } else {
      this.store.user.bookmarks['photos'].forEach((photo) => {
        if (photo.id == item.id) {
          bookmarked = true;
        }
      });
    }
    return bookmarked;
  }
}
