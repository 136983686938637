import { Experiment } from './experiment';

/**
 * Example config for an experiment - the variation weights should add up to 100
 *  It is important that you make the ids dash-cased
 *     {
 *       name: 'My test experiment',
 *       id: 'test_one',
 *       variations: [
 *           { name: 'red', id: 'red_one', weight: 20 },
 *           { name: 'blue', id: 'blue_two', weight: 80, winner?: true}
 *       ]
 *       status?: active, complete, failed
 *   }
 *
 */

/**
 * Example usage of the appExperiment directive - note a string is passed to the directive separated by a colon i.e. 'test:variation'
 *    <div *appExperiment="'test_one:variation_one'">This shows if variation_one is set for test_one for the user</div>
 *    <div *appExperiment="'test_one:variation_two'">This shows if variation_two is set for test_one for the user</div>
 */

/**
 * Example using experiment with ngClass
 * You would need to have the experimentService as public in the component to use this
 * You also need to make sure you include the following in your component class (if not using the directive):
 *   ngOnInit() {
 *     this.experimentService.viewed('test_one');
 *   }
 * <div [ngClass]="experimentService.experiments['test_one']">This element gets a class of the variationId applied to it </div>
 * <div [ngClass]="{'new-class' : experimentService.experiments['test_one'] == 'red_one' }">This element gets a class of new-class applied to it if the variation for test_one is red_one </div>
 */

export const experiments: Experiment[] = [
  {
    name: 'Header color demo experiment',
    id: 'header_color',
    variations: [
      { name: 'Normal', id: 'normal_option', weight: 30 },
      { name: 'Dark Mode', id: 'dark_mode', weight: 70 },
    ],
    reportUrl: 'https://mixpanel.com/report/463941/insights',
    status: 'complete',
  },
  {
    name: 'Signup order experiment',
    id: 'signup_order',
    variations: [
      { name: 'Normal', id: 'normal_order', weight: 50 },
      { name: 'Alternate Order', id: 'alternate_order', weight: 50 },
    ],
    reportUrl: 'https://mixpanel.com/report/463941/funnels#view/6263304/signup-order-experiment',
    status: 'complete',
  },
];
