import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutosizeDirective } from './autosize/autosize.directive';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { SpinnerComponent } from 'app/shared/spinner/spinner.component';
import { HasPhotosPipe } from './has-photos.pipe';
import {
  SmoothScrollToDirective,
  SmoothScrollDirective,
} from './smooth-scroll/smooth-scroll.directive';
import { RouterModule } from '@angular/router';
import { PaginateComponent } from 'app/shared/paginate/paginate.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SafePipe } from './safe.pipe';
import { RoleTypePipe } from './role-type.pipe';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { WysiwygComponent } from './wysiwyg/wysiwyg.component';
import { ImageDirective } from 'app/shared/image/image.directive';
import { SafeHtmlPipe } from './safe-html.pipe';
import { FeedbackService } from './feedback.service';
import { EmptyPhotoPipe } from './empty-photo.pipe';
import { VimeoPlayerComponent } from './vimeo-player/vimeo-player.component';
import { TrialFooterBannerComponent } from './trial-footer-banner/trial-footer-banner.component';
import { TrialActivateModalComponent } from './trial-activate-modal/trial-activate-modal.component';
import { PlanCardComponent } from './plan-card/plan-card.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { ItemHoverComponent } from './item-hover/item-hover.component';
import { NgAutocompleteComponent } from './ng-autocomplete/ng-autocomplete.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DtsSelectComponent } from './dts-select/dts-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FundingComponent } from './funding/funding.component';
import { FundedByComponent } from './funded-by/funded-by.component';
import { AlgoliaInsightsService } from './algolia/insights.service';

import { InViewportModule } from 'ng-in-viewport'; // https://k3nsei.gitbook.io/ng-in-viewport/getting-started/use-the-directive

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InlineSVGModule,
    ReactiveFormsModule,
    RouterModule,
    AutocompleteLibModule,
    NgSelectModule,
    InViewportModule,
  ],
  declarations: [
    AutocompleteComponent,
    AutosizeDirective,
    EmptyPhotoPipe,
    HasPhotosPipe,
    ImageDirective,
    ItemHoverComponent,
    ModalComponent,
    PaginateComponent,
    PlanCardComponent,
    RoleTypePipe,
    SafeHtmlPipe,
    SafePipe,
    SmoothScrollDirective,
    SmoothScrollToDirective,
    SpinnerComponent,
    TrialActivateModalComponent,
    TrialFooterBannerComponent,
    VimeoPlayerComponent,
    WysiwygComponent,
    NgAutocompleteComponent,
    DtsSelectComponent,
    FundingComponent,
    FundedByComponent,
  ],
  exports: [
    AutocompleteComponent,
    AutosizeDirective,
    EmptyPhotoPipe,
    FormsModule,
    HasPhotosPipe,
    ImageDirective,
    InlineSVGModule,
    ItemHoverComponent,
    ModalComponent,
    PaginateComponent,
    PlanCardComponent,
    ReactiveFormsModule,
    RoleTypePipe,
    RouterModule,
    SafeHtmlPipe,
    SafePipe,
    SmoothScrollDirective,
    SmoothScrollToDirective,
    SpinnerComponent,
    TrialActivateModalComponent,
    TrialFooterBannerComponent,
    VimeoPlayerComponent,
    WysiwygComponent,
    NgAutocompleteComponent,
    DtsSelectComponent,
    FundingComponent,
    FundedByComponent,
  ],
  providers: [FeedbackService, AlgoliaInsightsService],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
