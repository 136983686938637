<div class="modal">
  <div class="bg" (click)="app.toggleTrialUpgradeModal()"></div>
  <div class="inner upgrade" *ngIf="view == 'upgrade' || !view">
    <div class="left">
      <h1>Sorry! this is only availAbLe on Paid accounts</h1>
    </div>
    <div class="right">
      <h2>Upgrade your account</h2>
      <img src="../../../assets/images/paywall.gif" width="267" height="162" />
      <h2>Get full access to library and all resolutions.</h2>
      <ul>
        <li><span>Unlimited Photos</span></li>
        <li><span>All Photo Packs</span></li>
        <li><span>Request media</span></li>
        <li><span>All video packs</span></li>
      </ul>
      <a class="dts-button gradient-fill" (click)="view = 'activate'">UPGRADE FROM TRIAL💰</a>
    </div>
  </div>
  <div class="inner activate" *ngIf="view == 'activate'">
    <div class="left">
      <h1>YOU’RE ABOUT to activate your plan</h1>
    </div>
    <div class="right">
      <div class="plan-card">
        <app-plan-card
          [plan]="userPlan"
          [currPlan]="true"
          [location]="'upgrade-modal'"
          (activateSuccess)="view = 'success'"
        ></app-plan-card>
      </div>
    </div>
  </div>
  <div class="inner success" *ngIf="view == 'success'">
    <div class="left">
      <h2>Your Plan has been uPdated</h2>
      <p>
        Your plan has changes from <span>Trial</span> to
        <span>{{
          userPlan.id == 'experiment_13' || userPlan.id == 'premium_annual_145' ? 'Brand' : 'Agency'
        }}</span>
      </p>
      <p>Thank you big spender! We’ll be sure to put this to good use via our funding circle.</p>
      <div class="dts-button gradient-fill" (click)="app.toggleTrialUpgradeModal()">
        Start Exploring
      </div>
    </div>
    <div class="right">
      <h2>where the $$$ goes</h2>
      <div class="graphic">
        <svg class="circle" width="186" height="186" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="93" cy="93" r="92.5" stroke="#fff" />
        </svg>
        <svg width="12" height="6" fill="none" class="arrow" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1l5 4 5-4" stroke="#fff" />
        </svg>
        <p class="fund">Fund artist<br />projects</p>
        <p class="create">We create digital<br />media from the<br />project</p>
        <p class="add">Media is<br />added to your<br />library</p>
        <p class="memb">We take a % of<br />the membership<br />fees</p>
      </div>
    </div>
  </div>
</div>
