import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {
  // This is the cookie string
  private _data: object;
  public sessionData: object = {};

  constructor() {}

  set data(data: string) {
    this.parse(data);
  }

  getItem(key) {
    return this.sessionData && this.sessionData[key] && this.sessionData[key] != 'undefined'
      ? this.sessionData[key]
      : null;
  }

  setItem(name, value, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    if (typeof document != 'undefined') {
      this.sessionData[name] = value;
      document.cookie = '__session=' + JSON.stringify(this.sessionData) + ';' + expires + ';path=/';
    }
  }

  removeItem(name, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    delete this.sessionData[name];
    document.cookie = '__session=' + JSON.stringify(this.sessionData) + ';' + expires + ';path=/';
  }

  parse(rc) {
    const list = {};
    if (rc) {
      if (rc.match(';')) {
        rc.split(';').forEach(function (cookie) {
          const parts = cookie.split('=');
          list[parts.shift().trim()] = decodeURI(parts.join('='));
        });
      } else {
        const parts = rc.split('=');
        list[parts.shift().trim()] = decodeURI(parts.join('='));
      }
    }

    this._data = list;
    try {
      this.sessionData = this._data['__session'] ? JSON.parse(this._data['__session']) : {};
    } catch (error) {
      console.log('Error parsing the session');
    }

    return list;
  }
}
