<div class="page">
  <a class="back" (click)="location.back()">
    <img src="../../../assets/icons/arrow.svg" width="12" height="11" />
    Back</a
  >
  <div class="inner">
    <h1>HOW DID YOU HEAR ABOUT US?</h1>
    <div class="onboarding-survey">
      <form [formGroup]="form" (submit)="submit()" novalidate>
        <div class="survey">
          <div class="options">
            <div class="option" *ngFor="let option of options">
              <label>
                <input
                  type="radio"
                  formControlName="option"
                  (change)="handleChange($event)"
                  [value]="option"
                />{{ option }}
              </label>
            </div>
            <div class="option">
              <label>
                <input
                  type="radio"
                  formControlName="option"
                  [value]="'Other:'"
                  (change)="checkOtherInput($event) ? this.detailsInput.focus() : ''"
                />Other:
                <input
                  class="detailsInput"
                  formControlName="details"
                  maxlength="22"
                  #detailsInput
                />
              </label>
            </div>
          </div>
        </div>
        <div class="buttonWrap">
          <button
            class="dts-button black-fill"
            type="submit"
            [disabled]="form.controls['option'].value ? false : true"
          >
            NEXT
          </button>
          <a class="skip" (click)="navigateNext()">skip</a>
        </div>
      </form>
      <app-spinner *ngIf="loading"></app-spinner>
    </div>
  </div>
</div>
