<div class="page">
  <a class="back" (click)="location.back()">
    <img src="../../../assets/icons/arrow.svg" width="12" height="11" />
    Back</a
  >
  <div class="inner">
    <h2>1. WHAT INDUSTRY ARE YOU IN?</h2>
    <div class="icon-grid">
      <div class="selector" *ngFor="let option of store.onboarding.defaultIndustryValues">
        <input
          type="checkbox"
          name="industry-selector"
          value="{{ option.slug }}"
          id="industry-{{ option.slug }}"
          (change)="toggleValue($event, 'industryOptions')"
        />
        <label for="industry-{{ option.slug }}">
          <div [inlineSVG]="'/assets/images/industry-selector-' + option.slug + '.svg'"></div>
          <h3>{{ option.title }}</h3>
        </label>
      </div>
    </div>
  </div>
  <div class="inner">
    <h2>2. WHAT ARE YOUR CORE VALUES?</h2>
    <div class="icon-grid">
      <div class="selector" *ngFor="let value of store.onboarding.defaultCoreValues">
        <input
          type="checkbox"
          name="brand-value-selector"
          value="{{ value.slug }}"
          id="brand-value-{{ value.slug }}"
          (change)="toggleValue($event, 'coreValues')"
        />
        <label for="brand-value-{{ value.slug }}">
          <div [inlineSVG]="'/assets/images/brand-value-selector-' + value.slug + '.svg'"></div>
          <h3>{{ value.title }}</h3>
        </label>
      </div>
    </div>
  </div>
  <div class="inner">
    <h2>3. WHICH VISUAL REPRESENTS YOUR BRAND THE BEST?</h2>
    <div class="mood-boards">
      <div class="selector" *ngFor="let option of store.onboarding.defaultMoodBoardOptions">
        <input
          type="checkbox"
          name="mood-board-selector"
          value="{{ option.slug }}"
          id="mood-board-{{ option.slug }}"
          (change)="toggleValue($event, 'moodBoardOptions')"
        />
        <label for="mood-board-{{ option.slug }}" class="mood-board-{{ option.slug }}">
          <img
            src="../../../assets/images/mood-board-selector-{{ option.slug }}-h.png"
            width="120"
          />
          <img
            src="../../../assets/images/mood-board-selector-{{ option.slug }}-v.png"
            width="80"
          />
          <h3>{{ option.title }}</h3>
        </label>
      </div>
    </div>

    <button
      type="submit"
      class="dts-button black-fill"
      (click)="submitSelections()"
      [disabled]="
        store.onboarding.industryOptions.length == 0 &&
        store.onboarding.coreValues.length == 0 &&
        store.onboarding.moodBoardOptions.length == 0
      "
    >
      DONE
    </button>
    <a class="skip" [routerLink]="['/onboarding/license']">Skip</a>
  </div>
</div>
