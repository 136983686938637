import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { PaymentService } from 'app/payment/payment.service';
import { FormsModule } from '@angular/forms';
import { PaymentCardsComponent } from './payment-cards/payment-cards.component';
import { SharedModule } from 'app/shared/shared.module';
import { PaymentFormSplitComponent } from 'app/payment/payment-form-split/payment-form-split.component';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule],
  declarations: [PaymentFormComponent, PaymentCardsComponent, PaymentFormSplitComponent],
  exports: [PaymentFormComponent, PaymentCardsComponent, PaymentFormSplitComponent],
  providers: [PaymentService],
})
export class PaymentModule {}
