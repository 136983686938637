import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import startRain from '../../shared/emoji-rain';
import { AppStore } from 'app/app-store.service';
import { Router } from '@angular/router';
import { OnboardingService } from '../onboarding.service';
import { AnalyticsService } from 'app/core/analytics.service';

@Component({
  selector: 'app-onboarding-welcome',
  templateUrl: './onboarding-welcome.component.html',
  styleUrls: ['./onboarding-welcome.component.scss'],
})
export class OnboardingWelcomeComponent implements OnInit, AfterViewInit {
  public data: object;

  constructor(
    private analyticsService: AnalyticsService,
    public onboardingService: OnboardingService,
    public store: AppStore,
    public router: Router
  ) {}

  ngOnInit() {
    this.onboardingService.load();
    this.store.onboarding.step = 'welcome';
    this.data = JSON.parse(localStorage.getItem('signup'));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      startRain(['🖤', '💀', '⚡️']);
    }, 100);
  }

  setWelcome() {
    this.store.onboarding.letsGo = true;
    this.onboardingService.save();
    this.analyticsService.track(
      'Onboarding step complete',
      this.onboardingService.getSubmitableData()
    );
    this.router.navigate(['/onboarding/setup']);
  }
}
