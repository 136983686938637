import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Guards
import { AuthGuard } from './auth.guard';

// Modules
import { AuthRoutingModule } from 'app/auth/auth-routing.module';

// Components
import { LoginComponent } from './login/login.component';

// Services
import { AuthService } from './auth.service';
import { AuthForgotPaswordComponent } from './forgot-password/auth-forgot-pasword.component';
import { AuthResetPasswordComponent } from 'app/auth/reset-password/auth-reset-password.component';
import { SharedModule } from 'app/shared/shared.module';
import { AuthActiveGuard } from 'app/auth/auth-active.guard';

@NgModule({
  imports: [CommonModule, AuthRoutingModule, FormsModule, ReactiveFormsModule, SharedModule],
  declarations: [LoginComponent, AuthForgotPaswordComponent, AuthResetPasswordComponent],
  providers: [AuthGuard, AuthActiveGuard, AuthService],
  exports: [LoginComponent],
})
export class AuthModule {}
