import { Injectable } from '@angular/core';
import { AppStore } from '../app-store.service';
import { HttpClient } from '@angular/common/http';
import { AnalyticsService } from 'app/core/analytics.service';

@Injectable()
export class EventService {
  constructor(
    public analyticsService: AnalyticsService,
    private store: AppStore,
    private http: HttpClient
  ) {}

  /**
   * Get list of events
   * @param event
   */
  get(event: string, offset: number) {
    return this.http.get(
      this.store.config.apiPath + '/event?limit=10&event=' + event + '&offset=' + offset
    );
  }

  /**
   * Track an event
   * @param event
   * @param properties
   */
  track(event: string, properties: object = {}) {
    // enhance properties
    const additionalProperties = {
      feed: this.store.feed,
      search_term: this.store.algolia.searchTerm ?? null,
    };

    properties = { ...properties, ...additionalProperties };

    this.analyticsService.track(event, properties);
    this.http
      .post(this.store.config.apiPath + '/event', { event: event, properties: properties })
      .subscribe((res) => {});
  }
}
