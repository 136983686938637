import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingWelcomeComponent } from './welcome/onboarding-welcome.component';
import { OnboardingRoutingModule } from './onboarding-routing.module';
import { OnboardingComponent } from './onboarding.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { environment } from 'environments/environment';
import { OnboardingSetupComponent } from './setup/onboarding-setup.component';
import { OnboardingLicenseComponent } from './license/onboarding-license.component';
import { OnboardingService } from './onboarding.service';
import { SharedModule } from 'app/shared/shared.module';
import { OnboardingTrialModalComponent } from './trial-modal/onboarding-trial-modal.component';
import { OnboardingSurveyComponent } from './survey/onboarding-survey.component';

@NgModule({
  imports: [CommonModule, SharedModule, OnboardingRoutingModule, FormsModule],
  declarations: [
    OnboardingWelcomeComponent,
    OnboardingComponent,
    OnboardingSetupComponent,
    OnboardingLicenseComponent,
    OnboardingTrialModalComponent,
    OnboardingSurveyComponent,
  ],
  providers: [OnboardingService],
  exports: [OnboardingTrialModalComponent],
})
export class OnboardingModule {}
