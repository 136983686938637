import { Directive, ElementRef, Input, Renderer2, HostListener } from '@angular/core';
import { AppStore } from 'app/app-store.service';
import { environment } from 'environments/environment';

import ImgixClient from 'imgix-core-js';

@Directive({
  selector: '[imgix-image]',
})
export class ImageDirective {
  @Input('imgix-url') image: string;
  @Input('imgix-width') width: string;
  @Input('imgix-height') height: string;
  @Input('imgix-source') source: string;
  @Input('imgix-fit') fit: string;
  @Input('imgix-auto') auto: string;
  @Input('imgix-quality') quality: string;
  @Input('imgix-type') type: string;
  @Input('imgix-name') name: string;
  @Input('imgix-id') id: string;
  @Input('imgix-blur') blur: number;

  public imgixURL: string;
  public client;
  public baseURL: string;

  constructor(public store: AppStore, private el: ElementRef, private renderer: Renderer2) {
    this.client = new ImgixClient({
      domain: 'deathtostock.imgix.net',
      secureURLToken: environment.imgix_token,
    });

    this.store.windowResizeFinished.subscribe((x) => {
      this.loadImages();
    });
  }

  ngOnChanges() {
    this.loadImages();
  }

  loadImages() {
    const width =
      this.width && this.width != 'default' ? this.width : this.el.nativeElement.offsetWidth;
    const height =
      this.height && this.height != 'default' ? this.height : this.el.nativeElement.offsetHeight;

    if (!width || !height) {
      return;
    } // we don't accept 0 width or height (often occurs from resizing window with detached route elements)

    const params = {
      w: width,
      h: height,
      fit: this.fit ? this.fit : 'clip',
      dpr: window['devicePixelRatio'] ? window['devicePixelRatio'] : 1,
      auto: this.auto ? this.auto : 'compress',
      q: this.quality ? this.quality : 75,
    };

    // Special settings for animated gifs
    if (this.image && this.image.match('.gif')) {
      delete params['q'];
      params['auto'] = 'format, compress';
      params['gif-q'] = 35;
    }

    /**
     * The commented out lines below are for toggling images off using Imgix if say their service goes down
     * We may want to add somehting to toggle this
     */
    if (this.image.indexOf('deathto-staging') !== -1) {
      this.imgixURL = this.image.replace(
        'https://s3.amazonaws.com/deathto-staging/photos/files/',
        ''
      );
      this.baseURL = 'https://deathtostock-staging.imgix.net/';
      // this.imgixURL = this.image.replace('https://s3.amazonaws.com/deathto-staging/photos/files/', 'https://s3.amazonaws.com/deathto-staging/photos/files/');
    } else if (this.image.indexOf('deathtostock') !== -1) {
      this.imgixURL = this.image.replace('https://s3.amazonaws.com/deathtostock/photos/files/', '');
      this.baseURL = 'https://deathtostock.imgix.net/';
      // this.imgixURL = this.image.replace('https://s3.amazonaws.com/deathtostock/photos/files/', 'https://s3.amazonaws.com/deathtostock/photos/files/');
    }

    if (this.type == 'gif') {
      // no params to speed up load time
      const url = this.baseURL + this.imgixURL;
      this.el.nativeElement.src = url;
      // this.el.nativeElement.src = this.imgixURL;
    } else if (this.type == 'backgroundImage') {
      // let url = this.client.buildURL(this.imgixURL, params);
      const url = this.image;
      this.el.nativeElement.style.backgroundImage = 'url(' + url + ')';
    } else if (this.type == 'download') {
      const photo = this.store.photos[this.id];
      const pack = photo.pack ? photo.pack : this.store.packs[photo.pack_id];
      const ext = this.name.split('.').pop();
      const dlPack = pack?.name ?? 'Misc';
      const dlArtist = this.store.artists?.[pack.artist_id]?.full_name ?? 'Misc';
      const dlName = `DTS_${dlPack}_${dlArtist}_${this.id}.${ext}`.replace(/ /g, '_');

      params['dl'] = dlName;

      const url = this.client.buildURL(this.imgixURL, params);

      this.el.nativeElement.href = url;
    } else {
      const url = this.client.buildURL(this.imgixURL, params);
      this.el.nativeElement.src = this.image;
    }
  }
}
