import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { AppStore } from 'app/app-store.service';

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  constructor(@Inject(AppStore) private storage: AppStore) {}

  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuse;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // clear all other routes and lets store the new one
    this.handlers = {};
    if (!!handle) {
      this.handlers[this.getPath(route.root)] = handle;
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!route.data.reuse && !!this.handlers[this.getPath(route.root)];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig || !route.data.reuse) {
      return null;
    }
    return this.handlers[this.getPath(route.root)];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // If our current and future routes are cached, lets clear the future route cache so it will reload
    if (future.data.reuse && curr.data.reuse) {
      delete this.handlers[this.getPath(future.root)];
    }

    return future.routeConfig === curr.routeConfig;
  }

  getPath(route, path = '') {
    if (route) {
      if (route.routeConfig && route.routeConfig.path != '') {
        path += route.routeConfig.path;
      }
      if (route.firstChild) {
        if (route.firstChild.routeConfig && route.firstChild.routeConfig.path != '') {
          path += '/' + route.firstChild.routeConfig.path;
        }
        if (route.firstChild.firstChild) {
          if (
            route.firstChild.firstChild.routeConfig &&
            route.firstChild.firstChild.routeConfig.path != ''
          ) {
            path += '/' + route.firstChild.firstChild.routeConfig.path;
          }
          if (route.firstChild.firstChild.firstChild) {
            if (
              route.firstChild.firstChild.firstChild.routeConfig &&
              route.firstChild.firstChild.firstChild.routeConfig.path != ''
            ) {
              path += '/' + route.firstChild.firstChild.firstChild.routeConfig.path;
            }
          }
        }
      }
    }
    return path;
  }
}
