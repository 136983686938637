import { Injectable } from '@angular/core';
import { AppStore } from 'app/app-store.service';
import { Photo } from 'app/photo/photo';
import { Tag } from 'app/tag/tag';
import { MessageService } from 'app/message/message.service';
import { Subject, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Pack } from '../pack/pack';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TagService {
  public loading: Subject<boolean> = new Subject();

  constructor(
    private store: AppStore,
    private http: HttpClient,
    public messageService: MessageService
  ) {
    this.loading.next(false);
  }

  /**
   * Get tags for autocomplete
   * @param search
   */
  get(search: string): Observable<Tag[]> {
    return this.http.get<Tag[]>(this.store.config.apiPath + '/tag/?search=' + search);
  }

  /**
   * Get mood tags
   */
  getMood(): Observable<Tag[]> {
    return this.http.get<Tag[]>(this.store.config.apiPath + '/tag/category/mood/');
  }

  /**
   * Does a tag exist in an array of tags
   * @param tag
   * @param tags
   */
  hasTag(tag: string, tags: Array<Tag>) {
    for (const objectTag of tags) {
      if (objectTag.name == tag) {
        return true;
      }
    }
    return false;
  }

  /**
   * Doe this photo have a tag
   * @param tag
   * @param photo
   */
  tagged(tag: string, object: Photo | Pack) {
    return this.hasTag(tag, object.tags);
  }

  /**
   * Add tags to a photo
   * @param tags
   * @param photo
   */
  add(tags: any, type: string, object: Photo | Pack) {
    let message;
    tags = tags.split(',');
    // remove blank strings
    tags = tags.filter(function (tag) {
      if (/^[a-zA-Z0-9 _&-]*$/g.test(tag)) {
        return tag.toLowerCase();
      } else {
        message = 'We only accept tags with letters or numbers';
      }
    });

    // Check if the photo already has all the tags we are trying to add
    let shouldSync = false;
    for (const t of tags) {
      shouldSync = this.tagged(t, object) ? false : true;
    }

    if (type == 'pack') {
      type = 'admin/project';
    }

    // If not then do the request
    if (shouldSync) {
      this.loading.next(true);
      this.http
        .post<Tag[]>(this.store.config.apiPath + '/' + type + '/tag', {
          id: object.id,
          tags: tags,
        })
        .subscribe(
          (data) => {
            object.tags = data;
            this.loading.next(false);
          },
          (error) => {
            this.messageService.error('Oops there was a problem adding tags');
            this.loading.next(false);
          }
        );
    }

    return message;
  }

  /**
   * Add tags to an array of photos
   * @param tags
   * @param photos
   */
  addMulti(tags: Array<string>, photos: Array<Photo>) {
    return this.http
      .post(this.store.config.apiPath + '/photo/multi-tag', { tags, photos })
      .switchMap((data) => {
        photos.forEach((photo) => {
          photo.tags = data['tags'];
        });
        return of(data);
      });
  }

  /**
   * Remove a tag from a photo
   * @param tag
   * @param photo
   */
  remove(tag: Tag, type: string, object: Photo | Pack) {
    if (type == 'pack') {
      type = 'admin/project';
    }

    this.http
      .patch<Tag[]>(this.store.config.apiPath + '/' + type + '/tag/', {
        id: object.id,
        tag_id: tag.id,
      })
      .subscribe(
        (data) => {
          object.tags = data;
        },
        (error) => {
          this.messageService.error('Oops there was a problem removing the tag');
        }
      );
  }

  async getTagData(term: string) {
    if (!term.length) {
      return [];
    }
    const res = await this.get(term).toPromise();

    // if tag doesdnt exist allow it to be added
    if (res && !res['data'].find((existingTerm) => existingTerm.name == term)) {
      res['data'].push({
        id: 0,
        name: `Add "${term}"`,
        newTag: term,
      });
    }
    return res['data'];
  }
}
