import { Injectable } from '@angular/core';
import { User, CreditCard } from 'app/user/user';
import { AppStore } from 'app/app-store.service';
import { of, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { PaymentToken } from 'app/payment/payment-token';
import { AuthService } from 'app/auth/auth.service';
import { Plan } from 'app/shared/plan';
import { HttpClient } from '@angular/common/http';
import { AnalyticsService } from 'app/core/analytics.service';
import { switchMap, catchError } from 'rxjs/operators';

export interface Options {
  id?: number;
  page?: number;
  search?: string;
  show?: number;
  reload?: boolean;
}

@Injectable()
export class UserService {
  constructor(
    public analyticsService: AnalyticsService,
    private auth: AuthService,
    private router: Router,
    private store: AppStore,
    private http: HttpClient
  ) {
    if (!this.store.user && this.store.token) {
      this.get().subscribe(
        (res) => {},
        (error) => {
          this.auth.logout();
        }
      );
    }
  }

  /**
   *  Update a users password
   *  @return Observable
   */
  resetPassword(data): Observable<any> {
    return this.http.post(this.store.config.apiPath + '/user/reset-password', data);
  }

  /**
   * Build the url for get requests
   * @param options
   */
  buildUrl(options?: Options) {
    if (options && options.id) {
      return this.store.config.apiPath + '/user/' + options.id;
    } else if (options && options.page) {
      let url = this.store.config.apiPath + '/user/?page=' + options.page;
      if (options.show) {
        url += '&limit=' + options.show;
      }
      if (options.search) {
        url += '&search=' + options.search;
      }
      return url;
    } else {
      return this.store.config.apiPath + '/user/tokenBasedLogin/';
      // return this.store.config.apiPath + '/user/authenticated/';
    }
  }

  /**
   *  Get a logged in user - it uses the JWT to get the logged in user
   *  @return Observable
   */
  get(options?: Options): Observable<any> {
    const ids = [];
    // If we already have the user in the store then just return it
    if (options && options.id && this.store.users[options.id] && !options.reload) {
      ids.push(options.id);
      return of(ids);
      // return Observable.create((observer) => observer.next(ids));
    }
    // Otherwise we go get the user(s)
    return this.http.get(this.buildUrl(options)).pipe(
      switchMap((data) => {
        // If we are trying to get a list of users
        if (options && options.page && data['users']) {
          this.store.usersTotal = data['users']['total'];

          for (const user of data['users']['data']) {
            this.store.users[user.id] = user;
            ids.push(user.id);
          }
        } else if (data['user']) {
          ids.push(data['user']['id']);
          this.store.users[data['user']['id']] = data['user'];
          if (!options || !options.id) {
            // If we get a valid user then set the user on the store
            this.store.user = data['user'];
          }
        }
        return of(ids);
      }),
      catchError((err) => {
        return of(err);
      })
    );
  }

  /**
   * Get payment history from the server
   * @param userId
   */
  getPayments(userId: number): Observable<any> {
    return this.http.get(this.store.config.apiPath + '/user/invoices/' + userId);
  }

  /**
   * Get Stripe customer information from the server
   * @param customerId
   */
  getStripeCustomer(userId: number): Observable<any> {
    return this.http.post(this.store.config.apiPath + '/customer/', {
      user_id: userId,
    });
  }

  /**
   * Create a new user
   */
  create(user): Observable<any> {
    // If a coupon is set and is a valid coupon for that plan then apply it
    // if (this.store.coupon && this.store.coupons.find(coupon => coupon.id == this.store.coupon.id && coupon.plan_id == user.planId)) {
    //   user.coupon = this.store.coupon.id;
    // }

    return this.http.post(this.store.config.apiPath + '/signup', user).switchMap((data) => {
      // Send data to Tapfiliate
      try {
        const stripeCustomerId = data['user']['stripe_customer']['id'] ?? null;
        if (stripeCustomerId) {
          window['tap']('trial', stripeCustomerId);
          // console.log('tapfiliate trial logged', stripeCustomerId);
        }
      } catch (error) {
        // console.log('Tapfilite error', error);
      }

      // Set the token and user
      localStorage.setItem('token', data['token']);
      // Track the event in Segment
      this.analyticsService.track('Registered account');

      this.store.user = data['user'];
      this.store.userLoaded.next(true);
      this.store.loggedIn.next(true);
      return of(data);
      // return Observable.create((observer) => observer.next(data));
    });
  }

  /**
   * Check that a user with email doesn't already exist
   * @param email
   */
  checkEmail(email: string): Observable<any> {
    return this.http.post(this.store.config.apiPath + '/user/check-email/', { email });
  }

  /**
   * Update a user
   */
  update(user: User): Observable<any> {
    return this.http.patch(this.store.config.apiPath + '/user', user);
  }

  /**
   * Delete a user
   * @param id
   */
  delete(user: User) {
    return this.http.delete(this.store.config.apiPath + '/user/' + user.id);
  }

  /**
   * Change a subscription from a user
   * @param id
   */
  changeSubscription(user: User, plan: Plan, applyTrial: boolean = false): Observable<any> {
    return this.http.post(this.store.config.apiPath + '/user/change-subscription/', {
      id: user.id,
      plan_id: plan.id,
      applyTrial,
    });
  }

  /**
   * Cancel a subscription from a user
   * @param id
   */
  cancelSubscription(user: User): Observable<any> {
    return this.http.post(this.store.config.apiPath + '/user/cancel-subscription/', {
      id: user.id,
      customer_id: user.customer_id,
    });
  }

  /**
   * Make a user active
   * @param id
   */
  makeActive(id: number) {}

  /**
   *  Add a credit card to the user
   */
  addCard(token: PaymentToken): Observable<CreditCard[]> {
    return this.http
      .post<CreditCard[]>(this.store.config.apiPath + '/card/', token)
      .switchMap((data) => {
        this.store.user.cards = data;
        return of(data);
      });
  }

  /**
   *  Add a credit card to the user
   */
  deleteCard(cardId: string): Observable<CreditCard[]> {
    return this.http
      .delete<CreditCard[]>(this.store.config.apiPath + '/card/' + cardId)
      .switchMap((data) => {
        this.store.user.cards = data;
        return of(data);
      });
  }

  /**
   *  Load the credit cards on the user
   */
  loadCards(): Observable<CreditCard[]> {
    return this.http.get<CreditCard[]>(this.store.config.apiPath + '/card/').switchMap((data) => {
      this.store.user.cards = data['data'];
      return of(data);
    });
  }

  /**
   *  Load roles that can be applied to a user
   */
  loadRoles(): Observable<any> {
    return this.http.get(this.store.config.apiPath + '/user/roles/');
  }

  /**
   * Upgrade a user from a trial to full plan
   * @param user
   */
  activatePlan(user: User): Observable<any> {
    return this.http
      .post<User>(this.store.config.apiPath + '/user/activate-plan/', { id: user.id })
      .pipe(
        switchMap((res) => {
          this.store.user = res;
          return of(res);
        })
      );
  }

  addChurnCoupon() {
    return this.http.post(this.store.config.apiPath + '/user/churn-coupon/', {});
  }

  purchaseForeverLicense(sourceId) {
    return this.http
      .post(this.store.config.apiPath + '/user/purchaseForeverLicense', {
        sourceId: sourceId,
      })
      .toPromise();
  }

  getForeverPrice() {
    return this.http.get(this.store.config.apiPath + '/user/calculate-price/');
  }

  getUserActivityReport(): Observable<any> {
    return this.http.get(this.store.config.apiPath + '/user/activity-report');
  }

  updateUserCuratorScore(userId: number, score: number) {
    return this.http
      .post(this.store.config.apiPath + '/user/updateCuratorScore', { userId, score })
      .toPromise();
  }

  getPreCancellationData() {
    return this.http.get(this.store.config.apiPath + '/user/preCancellationData').toPromise();
  }
}
