import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Plan } from '../plan';
import { AnalyticsService } from 'app/core/analytics.service';
import { UserService } from 'app/user/user.service';
import { AppStore } from 'app/app-store.service';
import { MessageService } from 'app/message/message.service';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
})
export class PlanCardComponent implements OnInit {
  @Input() plan: Plan;
  @Input() location: string;
  @Input() currPlan: boolean;
  @Output() activateSuccess: EventEmitter<boolean> = new EventEmitter();
  @Output() activateInit: EventEmitter<boolean> = new EventEmitter();
  public loading: boolean;
  public title: string;
  public billing: string;
  public price: any;
  public license: string;
  public cardFailed: boolean;
  public errorMessage: string;
  public discountText: string;

  constructor(
    public analyticsService: AnalyticsService,
    public messageService: MessageService,
    public store: AppStore,
    public userService: UserService
  ) {}

  ngOnInit() {
    if (this.plan.id === 'freeForever') {
      this.title = 'You are free forever!';
      this.license = 'An unlimited premium subscription to all Death to Stock Media.';
    } else if (this.plan.id) {
      if (this.plan.id === 'experiment_13' || this.plan.id === 'premium_annual_145') {
        this.title = 'Brand';
        this.license = 'Single Business Licence';
      }
      if (
        this.plan.id === 'agency_monthly' ||
        this.plan.id === 'agency_monthly_39' ||
        this.plan.id === 'agency_yearly' ||
        this.plan.id === 'agency_yearly_399'
      ) {
        this.title = 'Agency';
        this.license = 'Client Licence';
      }
      if (this.plan.id === 'plan_DiUzSZpcasS5mx') {
        this.title = 'Enterprise';
        this.license = 'Product Licence';
      }
      this.billing = 'USD billed ' + this.plan.period;
      this.price = this.plan.price;

      if (
        this.currPlan &&
        this.store.users[this.store.currentUserId]?.stripe_customer?.upcoming_invoice?.total
      ) {
        const upcomingInvoice =
          this.store.users[this.store.currentUserId].stripe_customer.upcoming_invoice;
        this.price = upcomingInvoice.total / 100;

        if (upcomingInvoice.discount?.coupon) {
          if (upcomingInvoice.discount?.coupon?.metadata?.description) {
            this.discountText = upcomingInvoice.discount.coupon.metadata.description;
          } else {
            this.discountText = 'Discount applied';
          }
        }
      }
    } else {
      this.title = 'Trial';
      this.billing = '14 DAY TRIAL';
      this.price = 0;
      this.license = 'Trial Account Licence';
    }
  }

  activatePlan() {
    if (this.location === 'upgrade-modal' || (this.store.trialMode && this.currPlan)) {
      this.loading = true;
      this.analyticsService.track('Clicked activate plan', {
        location: this.location,
        plan: this.plan.name,
      });
      this.userService.activatePlan(this.store.user).subscribe(
        (result) => {
          this.loading = false;
          this.activateSuccess.emit(true);
        },
        (error) => {
          this.loading = false;

          let errorMsg = 'There was an error activating your plan. Please get in touch for help.';
          if (error.error.message) {
            errorMsg =
              error.error.message == 'Your card was declined.'
                ? 'Sorry, your card was declined.'
                : error.message;
          }
          this.messageService.error(errorMsg);
          this.errorMessage = errorMsg;
          this.cardFailed = true;
        }
      );
    } else {
      this.activateInit.emit(true);
    }
  }
}
