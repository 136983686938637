import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from '../onboarding.service';
import { AppStore } from 'app/app-store.service';
import { AnalyticsService } from 'app/core/analytics.service';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-onboarding-survey',
  templateUrl: './onboarding-survey.component.html',
  styleUrls: ['./onboarding-survey.component.scss'],
})
export class OnboardingSurveyComponent implements OnInit {
  public form: FormGroup;
  public loading: boolean;
  public data: object;
  public options: Array<string> = [
    'Instagram',
    'Online Search, e.g. Google',
    'Blog or Podcast',
    'From a friend',
    'Our Email List',
  ];

  constructor(
    private analyticsService: AnalyticsService,
    public onboardingService: OnboardingService,
    public store: AppStore,
    public router: Router,
    public location: Location,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.onboardingService.load();
    this.store.onboarding.step = 'survey';
    this.form = this.formBuilder.group({
      option: ['', [Validators.required]],
      details: [''],
    });
    this.data = JSON.parse(localStorage.getItem('signup'));
    this.form.controls['details'].disable();
  }

  /**
   * Submit the survey form
   */
  submit() {
    this.loading = true;

    let trackEvent = null;

    if (this.form.controls['option'].value === 'Other:' && this.form.controls['option'].value) {
      const optionSelection = this.form.controls['option'].value;
      const details = this.form.controls['details'].value;
      const surveyAnswer = [optionSelection, details];
      trackEvent = `Other: ${details}`;
      this.store.onboarding['survey'] = surveyAnswer;
      this.onboardingService.save();
    } else if (this.form.controls['option'].value) {
      const surveyAnswer = [this.form.controls['option'].value];
      this.store.onboarding['survey'] = surveyAnswer;
      trackEvent = this.form.controls['option'].value;
      this.onboardingService.save();
    } else {
      return;
    }

    this.analyticsService.track('Onboarding referral question', { answer: trackEvent });

    this.navigateNext();
  }

  navigateNext() {
    const signupPath =
      this.data && this.data['type'] && this.data['type'] !== ''
        ? '/signup/' + this.data['type']
        : '/signup';
    this.router.navigate([signupPath], { queryParams: { step: 2 } });
  }

  checkOtherInput(e) {
    if (e.target.checked) {
      this.form.controls['details'].enable();
      return true;
    } else {
      return false;
    }
  }

  handleChange(e) {
    if (e.target.checked) {
      this.form.controls['details'].disable();
    }
  }
}
